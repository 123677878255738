<template>
  <div v-bem>
    <div
      v-show="isShow"
      v-bem:shadow
    />
    <div
      v-show="isShow"
      :class="pageType"
      class="com-post-offline-dialog__wrap"
    >
      <div v-bem:content>
        <div v-bem:nav>
          <div
            v-bem:back
            @click="clickCancel"
          />
          <div v-bem:title>
            选择下架理由
          </div>
        </div>

        <div v-bem:reason>
          <ul v-bem:list>
            <li
              v-for="item in reason"
              :key="item.id"
              v-bem:item
              @click="clickReason(item)"
            >
              <span>
                {{ item.desc }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import manage from '@/api/manage';
import dialog from '@/ui/dialog/index';

export default {
  name: 'ComPostOfflineDialog',
  model: {
    prop: 'isShow',
    event: 'change',
  },
  props: {
    isShow: Boolean,
    pageType: {
      type: String,
      default: () => ({}),
    },
    reportData: {
      type: Function,
      default: () => ({}),
    },
    postInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      reason: [],
    };
  },
  mounted() {
    this.initReason();
  },
  methods: {
    initReason() {
      manage.getPunishReasons(1).then((result) => {
        this.reason = result?.punishReasonList || [];
      })
        .catch((error) => {
          this.$logger.error('getPunishReasons', error);
        });
    },
    clickCancel() {
      this.$emit('change', false);
    },
    clickReason(reason) {
      const confirmDialog = dialog({
        confirm: true,
        confirmText: '确认',
        cancelText: '取消',
        content: () => '是否将该内容下架？',
        onConfirm: () => {
          confirmDialog.close();
          const loadingStatus = this.$loading({
            message: '操作中...',
          });
          manage.offContent({
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
            offReason: reason.desc,
            reasonId: reason.id,
            isOn: false,
          }).then(() => {
            this.$emit('change', false);
            loadingStatus.close();
            this.$emit('update');
            this.$toast('操作成功');
            this.reportData({ operid: '1501000830601', ext1: '3', ext3: reason }, '内容详情页管理操作成功');
          })
            .catch(() => {
              loadingStatus.close();
            });
        },
        onCancel: () => {
          confirmDialog.close();
        },
      });
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
