/**
 * 帖子API
 * */
// import GamePlusAPI from '../util/gameplus-api';
// import postInfoMockData from '../mock/postInfo.json';
// eslint-disable-next-line no-unused-vars
import { gameplusApi } from '@/util/http';

export default {
  /**
   * 通过postId获取贴子信息
   * @param { Object } params
   * @param { Number } params.userId 当前用户ID，未登录传0
   * @param { Number } params.postId 帖子ID
   * @param { Number } params.postType 帖子类型 1 图文 2 问答
   * @param { Number } params.flag 1 折叠回答 2 是否需要拉取礼包数量
   * @return { Object } 返回获取结果
   * */
  getPostInfoByPostId({
    postId, postType, userId, flag = 0,
  } = {}) {
    return gameplusApi.request({
      url: '/community.GCFeedDetailSrv/GetHomePageFeedsDetail',
      data: {
        userId: userId ? Number(userId) : 0,
        feedId: Number(postId),
        postType,
        flag: Number(flag),
      },
      // mock: postInfoMockData,
      options: {
        errorHandler: false,
        // reqProto: 'GetHomePageFeedsDetailReq',
        // resProto: 'GetHomePageFeedsDetailRsp',
      },
    }).then(res => res);
  },
  /**
   * 获取当前用户对于帖子的行为状态
   * @param { Object } params
   * @param { Number } params.userId 帖子ID
   * @param { Number } params.postId 帖子类型 1 图文 2 问答
   * @return { Object } 返回获取结果
   * */
  getPostAction({ userId, postId } = {}) {
    return gameplusApi.request({
      url: '/community.PostSrv/GetMyActionWithOnePost',
      data: {
        userId,
        postId,
      },
      options: {
        excludeRet: [10015], // 这个不是错误，正常返回即可
      },
      // mock: postInfoMockData,
    }).then(res => Promise.resolve(res.result));
  },
  /**
   * 帖子行为
   * @param { Object } params
   * @param { Number } params.postId 帖子id或回答id或问题id
   * @param { Number } params.postType 帖子id或回答id或问题id
   * @param { Number } params.postCreatorId 帖子或回答的创建者id，前端带过来，节省后端查询一次
   * @param { Number } params.userId 用户id
   * @param { Number } params.groupId 圈子id
   * @param { Number } params.action 点赞：like,取消点赞：unlike,收藏：follow,取消收藏：unfollow,分享：shared,已阅：read,举报：accuse
   * @param { String } [params.info] 对于举报填举报原因，对于邀请回答，是被邀请的用户列表，逗号分割
   * @param { Object } options 配置
   * @return { Object } 返回获取结果
   * */
  postAction({
    postId, postType, postCreatorId, userId, groupId, action, info = null,
  }, options = {}) {
    return gameplusApi.request({
      url: '/community.PostSrv/PostAction',
      data: {
        postId,
        postType,
        postCreatorId,
        userId,
        groupId,
        action,
        info,
      },
      options: {
        ...options,
      },
      // mock: postInfoMockData,
    });
  },
  /**
   * 更改帖子信息
   * @param { Object } postInfo 帖子信息
   * @return { Object } 返回获取结果
   * */
  updatePost(postInfo) {
    return gameplusApi.request({
      url: '/community.PostSrv/UpdatePost',
      data: postInfo,
      // mock: postInfoMockData,
    });
  },
  /**
   * 删除帖子
   * @param { Object } postInfo 帖子信息
   * @return { Object } 返回获取结果
   * */
  deletePost({ id }) {
    return gameplusApi.request({
      url: '/community.PostSrv/DeletePost',
      data: {
        post_id: id,
      },
      // mock: postInfoMockData,
    });
  },
  /**
   * 拉取内容分享页模块顺序
   * @param  gid  圈子ID
   * @return { Object } 返回获取结果
   * */
  getContentShareModuleOrder(gid) {
    return gameplusApi.request({
      url: '/community.GCFeedDetailSrv/GetContentShareModuleOrder',
      data: {
        gid,
      },
    });
  },
};
