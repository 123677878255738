<template>
  <div v-bem>
    <div
      v-if="isShow"
      v-bem:shadow
      @touchmove.stop="preventDefault"
    />
    <div
      v-if="isShow"
      :class="pageType"
      class="com-strategy-lib-dialog__content"
    >
      <div
        v-bem:nav
        @touchmove.stop="preventDefault"
      >
        <div v-bem:title>
          {{ title }}
        </div>
        <div
          v-bem:cancel
          @click="clickCancel"
        />
        <div
          v-show="currentLevelIndex > 0"
          v-bem:back
          @click="clickBack"
        />
      </div>
      <div
        :class="pageType"
        @scroll="onScroll"
        class="com-strategy-lib-dialog__wrap"
      >
        <!-- 攻略页面 -->
        <ul
          v-show="currentLevelIndex === 0"
          v-bem:list
        >
          <li
            v-for="(item, index) in pageList"
            :key="item.pid"
            v-bem:item
            @click="clickPage(item, index)"
          >
            <span>{{ item.topTitle }}</span>
            <div v-bem:entry />
          </li>
          <li
            v-show="pageStatus.status === 'loading'"
            v-bem:status
          >
            <ListLoading />
          </li>
          <li
            v-show="pageStatus.status === 'error'"
            v-bem:status
          >
            <span>加载失败，请稍后重试</span>
          </li>

          <li
            v-show="pageStatus.status === 'suc' && pageList.length === 0"
            v-bem:status
          >
            <span>本圈子未配置攻略库</span>
          </li>
        </ul>

        <!-- 一级分类 -->
        <ul
          v-show="currentLevelIndex === 1"
          v-bem:list
        >
          <li
            v-for="(item, index) in l1List"
            :key="item.pid"
            v-bem:item
            @click="clickL1Item(item, index)"
          >
            <span>{{ item.categoryName }}</span>
            <div
              v-if="item.showType !== 1"
              v-bem:entry
            />
            <div
              v-else
              v-bem:btn
              @click="clickAddToStrategy('l1', item)"
            >
              移入
            </div>
          </li>
          <li
            v-show="l1Status.status === 'loading'"
            v-bem:status
          >
            <ListLoading />
          </li>

          <li
            v-show="l1Status.status === 'error'"
            v-bem:status
          >
            <span>加载失败，请稍后重试</span>
          </li>

          <li
            v-show="l1Status.status === 'suc' && l1List.length === 0"
            v-bem:status
          >
            <span>未配置分类</span>
          </li>
        </ul>

        <!-- 二级分类 -->
        <ul
          v-show="currentLevelIndex === 2"
          v-bem:list
        >
          <li
            v-for="(item) in l2List"
            :key="item.pid"
            v-bem:item
          >
            <span>{{ item.subTitle }}</span>
            <div
              v-bem:btn
              @click="clickAddToStrategy('l2', item)"
            >
              移入
            </div>
          </li>
          <li
            v-show="l2Status.status === 'loading'"
            v-bem:status
          >
            <ListLoading />
          </li>

          <li
            v-show="l2Status.status === 'error'"
            v-bem:status
          >
            <span>加载失败，请稍后重试</span>
          </li>
          <li
            v-show="l2Status.status === 'suc' && l2List.length === 0"
            v-bem:status
          >
            <span>未配置分类</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import manage from '@/api/manage';
import ListLoading from '@/ui/loading/list-loading.vue';
import dialog from '@/ui/dialog/index';
import { debounce } from '@/util/util';

export default {
  name: 'ComStrategyLibDialog',
  components: {
    ListLoading,
  },
  model: {
    prop: 'isShow',
    event: 'change',
  },
  props: {
    pageType: {
      type: String,
      default: () => ({}),
    },
    isShow: {
      type: Boolean,
      default: () => ({}),
    },
    reportData: {
      type: Function,
      default: () => ({}),
    },
    postInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      title: '选择攻略库',
      currentLevelIndex: 0,
      currentPageIndex: 0,
      currentL2Index: 0,
      pid: 0, // 当前的页面id
      l1Id: 0, // 当前的L1id
      levelList: ['page', 'l1', 'l2'],
      pageStatus: {
        page: 1,
        isFinish: 0,
        status: '',
      },
      l1Status: {
        page: 1,
        isFinish: 0,
        status: '',
      },
      l2Status: {
        page: 1,
        isFinish: 0,
        status: '',
      },
      pageList: [],
      l1List: [],
      l2List: [],
      groupId: 0,
    };
  },
  watch: {
    postInfo(value) {
      if (value && value.groupId !== this.groupId) {
        this.loadPageData(true);
      }
    },
    pid() {
      this.loadL1Data(true);
    },
    l1Id() {
      this.loadL2Data(true);
    },
  },
  mounted() {
    this.groupId = this.postInfo?.groupId;
    this.loadPageData();
  },
  methods: {
    onScroll() {
      if (!this.onScroll.handler) {
        this.onScroll.handler = debounce(() => {
          if (this.currentLevelIndex === 0) {
            this.loadPageData();
          } else if (this.currentLevelIndex === 1) {
            this.loadL1Data();
          } else if (this.currentLevelIndex === 2) {
            this.loadL2Data();
          }
        }, 50);
      }
      this.onScroll.handler();
    },
    preventDefault(event) {
      event.preventDefault();
      return false;
    },
    clickPage(item, index) {
      this.currentPageIndex = index;
      this.currentLevelIndex = 1;
      this.pid = item.pid;
    },
    clickL1Item(item, index) {
      if (item && item.showType !== 1) {
        this.l1Id = item.id;
        this.currentLevelIndex = 2;
        this.currentL2Index = index;
      }
    },
    clickAddToStrategy(type, item) {
      const confirmDialog = dialog({
        confirm: true,
        confirmText: '确认',
        cancelText: '取消',
        content: () => '是否将该内容加入攻略库？',
        onConfirm: () => {
          confirmDialog.close();
          const loadingStatus = this.$loading({
            message: '操作中...',
          });
          const params = {
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
            pageId: this.pid,
            l1Id: type === 'l1' ? item.id : this.l1Id,
            l2Id: type === 'l1' ? 0 : item.id,
          };
          manage.addToStrategy(params).then(() => {
            loadingStatus.close();
            this.$emit('update');
            this.$emit('change', false);
            this.$toast('操作成功');
            this.reportData({
              operid: '1501000830601', ext1: '13', ext2: [params.pageId, params.l1Id, params.l2Id].join('_'),
            }, '内容详情页管理操作成功');
          })
            .catch(() => {
              loadingStatus.close();
            });
        },
        onCancel: () => {
          confirmDialog.close();
        },
      });
    },
    loadPageData(isReload) {
      if (!this.groupId) {
        return;
      }
      if (isReload) { // 初始化状态
        this.pageStatus = {
          page: 1,
          isFinish: 0,
          status: '',
        };
      }
      const cache = this.loadPageData;

      const status = this.pageStatus;
      if (!isReload && (status.isFinish || cache.lock)) { // 加载完成了
        return;
      }

      status.status = 'loading'; // 加载中

      cache.lock = true; // 加锁
      clearTimeout(cache.timer);
      cache.timer = setTimeout(() => { // 超时解锁
        cache.lock = false;
      }, 3000);

      const priority = Date.now(); // 数据优先级判断，用于丢掉前面的请求
      cache.priority = priority;

      manage.getStrategyList({
        bandType: 1,
        bandId: this.groupId,
        page: status.page,
      }).then((res) => {
        if (cache.priority !== priority) {
          return;
        }
        cache.lock = false;
        status.status = 'suc';
        const list = [];
        if (res.pageList) {
          res.pageList.forEach((item) => {
            if (item.status === 1) {
              list.push(item);
            }
          });
        }
        if (status.page === 1) {
          this.pageList = list;
        } else {
          this.pageList = [].concat(this.pageList, list);
        }
        if (status.page >= res.pagination?.totalPage) {
          status.isFinish = true;
        }
        status.page += 1;
      })
        .catch(() => {
          if (cache.priority !== priority) {
            return;
          }
          cache.lock = false;
          status.status = 'error';
        });
    },
    loadL1Data(isReload) {
      if (!this.pid) {
        return;
      }
      if (isReload) { // 初始化状态
        this.l1Status = {
          page: 1,
          isFinish: 0,
          status: '',
        };
        this.l1List = [];
      }
      const cache = this.loadL1Data;

      const status = this.l1Status;
      if (!isReload && (status.isFinish || cache.lock)) { // 加载完成了
        return;
      }

      status.status = 'loading'; // 加载中

      cache.lock = true; // 加锁
      clearTimeout(cache.timer);
      cache.timer = setTimeout(() => { // 超时解锁
        cache.lock = false;
      }, 3000);

      const priority = Date.now(); // 数据优先级判断，用于丢掉前面的请求
      cache.priority = priority;

      manage.getStrategyL1List({
        pid: this.pid,
        page: status.page,
      }).then((res) => {
        if (cache.priority !== priority) {
          return;
        }
        cache.lock = false;
        status.status = 'suc';
        const list = [];
        if (res.l1List) {
          res.l1List.forEach((item) => {
            if (item.status === 1) {
              list.push(item);
            }
          });
        }
        if (status.page === 1) {
          this.l1List = list;
        } else {
          this.l1List = [].concat(this.l1List, list);
        }
        if (status.page >= res.pagination?.totalPage) {
          status.isFinish = true;
        }
        status.page += 1;
      })
        .catch(() => {
          if (cache.priority !== priority) {
            return;
          }
          cache.lock = false;
          status.status = 'error';
        });
    },
    loadL2Data(isReload) {
      if (!this.pid) {
        return;
      }
      if (isReload) { // 初始化状态
        this.l2Status = {
          page: 1,
          isFinish: 0,
          status: '',
        };
        this.l2List = [];
      }
      const cache = this.loadL2Data;

      const status = this.l2Status;
      if (!isReload && (status.isFinish || cache.lock)) { // 加载完成了
        return;
      }

      status.status = 'loading'; // 加载中

      cache.lock = true; // 加锁
      clearTimeout(cache.timer);
      cache.timer = setTimeout(() => { // 超时解锁
        cache.lock = false;
      }, 3000);

      const priority = Date.now(); // 数据优先级判断，用于丢掉前面的请求
      cache.priority = priority;

      manage.getStrategyL2List({
        l1Id: this.l1Id,
        page: status.page,
      }).then((res) => {
        if (cache.priority !== priority) {
          return;
        }
        cache.lock = false;
        status.status = 'suc';
        const list = [];
        if (res.l2List) {
          res.l2List.forEach((item) => {
            if (item && item.status === 1) {
              list.push(item);
            }
          });
        }
        if (status.page === 1) {
          this.l2List = list;
        } else {
          this.l2List = [].concat(this.l2List, list);
        }
        if (status.page >= res.pagination?.totalPage) {
          status.isFinish = true;
        }
        status.page += 1;
      })
        .catch(() => {
          if (cache.priority !== priority) {
            return;
          }
          cache.lock = false;
          status.status = 'error';
        });
    },
    clickCancel() {
      this.$emit('change', false);
    },
    clickBack() {
      this.currentLevelIndex -= 1;
      if (this.currentLevelIndex < 0) {
        this.currentLevelIndex = 0;
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
