import { mapState } from 'vuex';
import postApi from '@/api/post';
import { sendGlobalNotification } from '@/jsbridge';
import { logger } from '@/util/logger';

export default {
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      userInfo: state => state.user.userInfo,
    }),
  },
  methods: {
    // 阅读回调
    readPostCallback({
      postInfo,
    }) {
      const me = this;
      postApi.postAction({
        postId: postInfo.id,
        postCreatorId: postInfo.creatorId,
        groupId: postInfo.groupId,
        postType: postInfo.type,
        userId: this.userInfo ? this.userInfo.uid : 0,
        action: 'read',
      }, {
        errorHandler: 'false',
      }).then(() => {
        // console.log(`已阅读状态上报成功: ${JSON.stringify(res, null, 4)}`);
        // console.log('添加浏览日志: ', postInfo.id);
        // 添加浏览任务
        try {
          me.$store.dispatch('task/setTask', {
            name: 'viewPost',
            contentId: postInfo.id,
          });
        } catch (e) {
          logger.err('task/setTask', e);
        }
      })
        .catch((err) => {
          logger.err(`已阅读状态上报失败 err.data: ${JSON.stringify(err.data || {}, null, 4)}`);
        });
    },
    // 同步最新的数据到各个页面
    syncPostInfo({ postInfo }) {
      if (postInfo.commentCnt) {
        this.sendUpdatePostGlobalNotification({
          id: postInfo.id,
          operate: 'commentCnt',
          data: postInfo.commentCnt,
        });
      }
      if (postInfo.answerCnt) {
        this.sendUpdatePostGlobalNotification({
          id: postInfo.id,
          operate: 'answerCnt',
          data: postInfo.answerCnt,
        });
      }
      if (postInfo.likeCnt) {
        this.sendUpdatePostGlobalNotification({
          id: postInfo.id,
          operate: 'answerCnt',
          data: postInfo.likeCnt,
        });
      }
      if (postInfo.readCnt) {
        this.sendUpdatePostGlobalNotification({
          id: postInfo.id,
          operate: 'readCnt',
          data: postInfo.readCnt,
        });
      }
    },
    sendUpdatePostGlobalNotification({ id, operate, data }) {
      sendGlobalNotification('g_updatePost', {
        id: Number(id),
        operate,
        data,
      });
    },
    //
  },
};
