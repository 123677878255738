import Vue from 'vue';
import Dialog from './index.vue';

/**
 * Dialog 弹窗
 * @param {Object} options
 * @param {String} [options.type=default] 弹窗类型
 * @param {Boolean} [options.confirm=true] 是否有确认取消按钮
 * @param {String} [options.confirmText] 确认按钮文本内容
 * @param {String} [options.cancelText] 取消按钮文本内容
 * @param {Object} [options.confirmTextStyle] 确认按钮样式
 * @param {Object} [options.cancelTextStyle] 取消按钮样式
 * @param {Function} options.content 内容render函数
 * @param {Function} [options.footer] 底部render函数
 * @param {Function} [options.onConfirm] 点击confirm回掉函数
 * @param {Function} [options.onCancel] 点击cancel回掉函数
 */
export default function dialog(options = {}) {
  const DialogComp = new Vue({
    data() {
      return {
        show: true,
      };
    },
    render(h) {
      return h(Dialog, {
        props: {
          type: options.type || 'default',
          confirm: options.confirm || true,
          confirmText: options.confirmText || '确认',
          cancelText: options.cancelText || '取消',
          confirmTextStyle: options.confirmTextStyle,
          cancelTextStyle: options.cancelTextStyle,
          show: this.show,
        },
        scopedSlots: {
          default: options.content && (props => options.content(h, props)),
          footer: options.footer && (props => options.footer(h, props)),
        },
        on: {
          confirm: options.onConfirm,
          cancel: options.onCancel,
        },
      });
    },
  });
  const dialogInstance = DialogComp.$mount();
  document.body.appendChild(dialogInstance.$el);

  return {
    close() {
      dialogInstance.show = false;
      dialogInstance.$el.parentNode.removeChild(dialogInstance.$el);
    },
  };
}
