<template>
  <div
    ref="pswp"
    class="pswp"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <!-- Background of PhotoSwipe. It's a separate element as animating opacity is faster than rgba(). -->
    <div class="pswp__bg" />
    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides.
      PhotoSwipe keeps only 3 of them in the DOM to save memory.
      Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container">
        <div class="pswp__item" />
        <div class="pswp__item" />
        <div class="pswp__item" />
      </div>

      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter" />

          <button
            class="pswp__button pswp__button--close"
            title="Close (Esc)"
          />

          <!--            <button class="pswp__button pswp__button&#45;&#45;share" title="Share"></button>-->

          <!--            <button class="pswp__button pswp__button&#45;&#45;fs" title="Toggle fullscreen"></button>-->

          <!--            <button class="pswp__button pswp__button&#45;&#45;zoom" title="Zoom in/out"></button>-->

          <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut" />
              </div>
            </div>
          </div>
        </div>

        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip" />
        </div>

        <!-- <button class="pswp__button pswp__button&#45;&#45;arrow&#45;&#45;left" title="Previous (arrow left)">-->
        <!--  </button>-->

        <!--  <button class="pswp__button pswp__button&#45;&#45;arrow&#45;&#45;right" title="Next (arrow right)">-->
        <!--  </button>-->

        <div class="pswp__caption">
          <div class="pswp__caption__center" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 查看大图组件
 */

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';

export default {
  name: 'UiPhotoSwipeH5',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    images: { // [{url, width, height}]
      type: Array,
      default: () => [],
      validator: arr => arr.every(item => item.width && item.height && item.url),
    },
    index: { // 当前查看的图片下标
      type: Number,
      default: 0,
    },
    currentUrl: {
      type: String,
      default: '',
    },
  },
  mounted() {
    //
  },
  methods: {
    // 供外部调用的接口
    showPhotoSwipe({
      index, url, images,
    } = { index: 0 }) {
      // console.log('showPhotoSwipe', { index, url, images });
      const el = this.$refs.pswp;
      const imgArr = (images || this.images).map(item => ({
        src: item.url,
        w: item.width,
        h: item.height,
      }));
      let showIndex = index;
      // 如果传入了url，就用url找到下标
      if (url) {
        for (let i = 0; i < imgArr.length; i++) {
          if (imgArr[i].src === url) {
            showIndex = i;
            break;
          }
        }
      }
      const gallery = new PhotoSwipe(el, PhotoSwipeUIDefault, imgArr, {
        // showAnimationDuration: 0,
        index: showIndex,
        loop: false,
        errorMsg: '<div class="pswp__error-msg">图片加载失败</div>',
        preload: [0, 0],
        loadingIndicatorDelay: 0,
        history: false,
      });
      // gallery.listen('gettingData', function(index, item) {
      // if (item.w < 1 || item.h < 1) { // unknown size
      //   let img = new Image();
      //   img.onload = function() { // will get size after load
      //     item.w = this.width; // set image width
      //     item.h = this.height; // set image height
      //     console.log('123456');
      //   }
      //   img.src = item.src; // let's download image
      // }
      // });
      gallery.listen('afterChange', () => {
        // console.log(gallery.currItem);
        if (gallery.currItem.w === 0 || gallery.currItem.h === 0) {
          const img = new Image();
          img.onload = function fn() { // will get size after load
            gallery.currItem.w = this.width; // set image width
            gallery.currItem.h = this.height; // set image height
            gallery.invalidateCurrItems();
            gallery.updateSize(true);
          };
          img.src = gallery.currItem.src; // let's download image
        }
      });
      // gallery.listen('imageLoadComplete', (index, item) => {
      //   console.log(index);
      //   console.log(item);
      //   console.log('load complete');
      // });
      gallery.init();
      this.gallery = gallery;
    },
  },
};
</script>

<style>

</style>
