<template>
  <div class="com-video-player">
    <video
      id="txy-player-container"
      v-if="playInfo.type === 'txy'"
      :style="customStyle"
      preload="auto"
      playsinline
      webkit-playsinline
    />
    <div id="other-player-container" />
  </div>
</template>
<script>
import { getScript, loadCssLink } from '@/util/util';
import { logger } from '@/util/logger';
import { isServerRender } from '@/util/env';

// 播放器实例不能放在data里面，vue 会修改这个对象导致播放器出错;
let txvPlayer;
let txyPlayer;
// eslint-disable-next-line no-unused-vars
let defaultPlayer;

const getTencentCloudPlayer = (retry = false) => new Promise(((resolve, reject) => {
  if (window.TCPlayer) {
    resolve();
  } else {
    loadCssLink('//web.sdk.qcloud.com/player/tcplayer/release/v4.7.2/tcplayer.min.css');
    const p2 = getScript(`${window.location.protocol}//web.sdk.qcloud.com/player/tcplayer/release/v4.7.2/tcplayer.v4.7.2.min.js`);
    const p1 = Promise.resolve();
    p1
      .then(() => p2)
      .then(() => {
        if (window.TCPlayer) {
          resolve();
        } else if (!retry) {
          // 重试加载一次
          getTencentCloudPlayer(true);
        } else {
          reject();
        }
      });
  }
  return true;
}));
const getTencentCloudPlayerLite = (retry = false) => new Promise(((resolve, reject) => {
  if (window.TcPlayer) {
    resolve();
  } else {
    loadCssLink('//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.css');
    getScript(`${window.location.protocol}//imgcache.qq.com/open/qcloud/video/vcplayer/TcPlayer-2.3.2.js`).then(() => {
      if (window.TcPlayer) {
        resolve();
      } else if (!retry) {
        // 重试加载一次
        getTencentCloudPlayerLite(true);
      } else {
        reject();
      }
    });
  }
  return true;
}));
// 腾讯视频
const getTencentVideoPlayer = (retry = false) => new Promise(((resolve, reject) => {
  if (window.Txplayer) {
    resolve();
  } else {
    getScript(`${window.location.protocol}//vm.gtimg.cn/tencentvideo/txp/js/txplayer.js`).then(() => {
      if (window.Txplayer) {
        resolve();
      } else if (!retry) {
        // 重试加载一次
        getTencentVideoPlayer(true);
      } else {
        reject();
      }
    });
  }
  return true;
}));
export default {
  name: 'ComVideoPlayer',
  props: {
    playInfo: {
      type: Object,
      default() {
        return {
          type: 'other',
        };
      },
    },
  },
  data() {
    return {
      source: 'txy',
      width: '',
      height: '',
      customStyle: {},
    };
  },
  watch: {
    playInfo(newVal) {
      if (newVal) {
        this.initVideoPlayer();
      }
    },
  },
  mounted() {
    this.width = document.documentElement.clientWidth;
    this.height = this.width * (9 / 16);
    if (this.playInfo) this.initVideoPlayer();
  },
  methods: {
    initVideoPlayer() {
      if (isServerRender) return;
      if (this.playInfo.type === 'txy') {
        this.customStyle = {
          width: '100%',
          height: '100%',
        };
        if (txyPlayer) {
          txyPlayer.loadVideoByID({
            fileID: this.playInfo.fileId, // 请传入需要播放的视频 filID（必须）
            appID: this.playInfo.appId, // 请传入点播账号的 appID（必须）
            psign: this.playInfo.psign, // 加了防盗链
          });
        } else {
          getTencentCloudPlayer().then(() => {
            this.$nextTick(() => {
              txyPlayer = window.TCPlayer('txy-player-container', { // player-container-id 为播放器容器 ID，必须与 html 中一致
                fileID: this.playInfo.fileId, // 请传入需要播放的视频 filID（必须）
                appID: this.playInfo.appId, // 请传入点播账号的 appID（必须）
                psign: this.playInfo.psign, // 加了防盗链
                autoplay: typeof this.playInfo.autoplay !== 'undefined' ? this.playInfo.autoplay : false,
                bigPlayButton: typeof this.playInfo.bigPlayButton !== 'undefined' ? this.playInfo.bigPlayButton : true,
                posterImage: typeof this.playInfo.posterImage !== 'undefined' ? this.playInfo.posterImage : false,
                plugins: {
                  ContinuePlay: { // 开启续播功能
                    // auto: true, //[可选] 是否在视频播放后自动续播
                    // text:'上次播放至 ', //[可选] 提示文案
                    // btnText: '恢复播放' //[可选] 按钮文案
                  },
                },
              });
              txyPlayer.on('canplay', () => {
                logger.info('getTencentCloudPlayer videoCanPlay');
                this.$emit('videoCanPlay');
              });
              txyPlayer.on('error', (err) => {
                logger.info('getTencentCloudPlayer error', err);
              });
            });
          });
        }
      } else if (this.playInfo.type === 'txv' && this.playInfo.vid) {
        // 腾讯视频播放器
        const width = document.getElementById('other-player-container').clientWidth;
        const height = width / (16 / 9);
        getTencentVideoPlayer().then(() => {
          txvPlayer = new window.Txplayer({
            containerId: 'other-player-container',
            vid: this.playInfo.vid,
            width,
            height,
          });
          txvPlayer.on('ready', () => {
            logger.info('TencentVideoPlayer ready', this.playInfo);
          });
          txvPlayer.once('error', (err) => {
            // 播放出错，上报一次
            logger.error('TencentVideoPlayer error', err);
          });
        });
      } else if (this.playInfo.type === '' || this.playInfo.type === 'default') {
        getTencentCloudPlayerLite().then(() => {
          defaultPlayer = new window.TcPlayer('other-player-container', {
            mp4: this.playInfo.url, // 请替换成实际可用的播放地址
            autoplay: true, // iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
            width: this.width, // 视频的显示宽度，请尽量使用视频分辨率宽度
            height: this.height, // 视频的显示高度，请尽量使用视频分辨率高度
          });
        });
      }
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
