<template>
  <div class="ui-list-loading">
    <div class="ui-list-loading-inner" />
  </div>
</template>

<script>
export default {

};
</script>
<style lang="scss" src="./scss/list-loading.scss"></style>
