<template>
  <div class="vote-option">
    <img
      v-if="iconUrl"
      :src="iconUrl"
      class="vote-icon"
    >
    <div :class="descriptionClassName">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  /** 投票选项组件 */
  name: 'VoteOption',
  props: {
    // 投票选项基本信息
    // message VoteChoice {
    //   string name = 1 [(desc) = "名称"];
    //   string pic_url = 2 [(desc) = "图片"];
    // }
    // 投票选项返回信息
    // message VoteChoiceInfo {
    //   VoteChoice choice = 1 [(desc) = "选项信息"];
    //   uint64 num = 2 [(desc) = "票数, 投票后才展示"];
    //   bool is_chosen = 3 [(desc) = "是否选择"];
    // }
    choiceInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    iconUrl() {
      const url = this.choiceInfo.choice.picUrl;
      return url;
    },
    description() {
      const description = this.choiceInfo.choice.name;
      return description;
    },
    descriptionClassName() {
      return this.iconUrl ? 'description has-icon' : 'description';
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
