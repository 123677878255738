import { gameplusApi } from '@/util/http';

export default {
  getOffContentReason() {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/GetOffContentReason',
      data: {
      },
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      options: {
        errorHandler: false,
      },
    });
  },
  // 下架、上架内容
  offContent({
    groupId, contentId, offReason = '', reasonId, isOn,
  }) {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/OffContent',
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      data: {
        groupId: parseInt(groupId, 10),
        contentId: parseInt(contentId, 10),
        offReason,
        reasonIdV2: reasonId,
        isOn: !!isOn,
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },
  // 获取禁言理由列表
  getPunishReasons(punishType) {
    return gameplusApi.request({
      url: '/community.PunishSrv/GetPunishReasons',
      data: {
        punishType,
      },
      options: {
        errorHandler: false,
      },
    });
  },
  // 禁言用户
  banUser({
    contentId, groupId, banUid, banTime, isCancel, reasonId,
  }) {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/BanUser',
      data: {
        groupId: parseInt(groupId, 10),
        contentId: parseInt(contentId, 10),
        banUid,
        banTime,
        isCancel,
        reasonIdV2: reasonId,
      },
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },
  // 拉取用户权限列表
  getUserPermission() {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/GetUserPermission',
      data: {
      },
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      //  mock: getUserPermission,
      options: {
        errorHandler: false,
      },
    }).then((result) => {
      const perms = {};
      if (result?.perms?.length > 0) {
        result.perms.forEach((item) => {
          perms[item.tag] = item;
        });
      }
      return {
        ...result,
        perms,
      };
    });
  },
  // 加精
  addToBest({ contentId, groupId, isCancel }) {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/AddToBest',
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      data: {
        groupId: parseInt(groupId, 10),
        contentId: parseInt(contentId, 10),
        isCancel,
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },
  // 换圈子
  changeGroup({ contentId, groupId }) {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/ChangeGroup',
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      data: {
        groupId: parseInt(groupId, 10),
        contentId: parseInt(contentId, 10),
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },
  // 置顶
  topContent({
    contentId, groupId, isCancel, endTime,
  }) {
    const date = new Date(endTime);
    const formatEndTime = new Date(`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} 23:59:59`);

    return gameplusApi.request({
      url: '/community.GroupManageSrv/TopContent',
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      data: {
        groupId: parseInt(groupId, 10),
        contentId: parseInt(contentId, 10),
        startTime: 0,
        endTime: parseInt(formatEndTime.getTime() / 1000, 10) || 0,
        isCancel: !!isCancel,
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },
  // 公告
  addToNotice({
    contentId, groupId, isCancel, endTime,
  }) {
    const date = new Date(endTime);
    const formatEndTime = new Date(`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} 23:59:59`);

    return gameplusApi.request({
      url: '/community.GroupManageSrv/AddToNotice',
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      data: {
        groupId: parseInt(groupId, 10),
        contentId: parseInt(contentId, 10),
        startTime: 0,
        endTime: parseInt(formatEndTime.getTime() / 1000, 10) || 0,
        isCancel: !!isCancel,
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },
  // 沉贴
  downContent({
    contentId, groupId, isCancel,
  }) {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/DownContent',
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      data: {
        groupId: parseInt(groupId, 10),
        contentId: parseInt(contentId, 10),
        isCancel: !!isCancel,
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },
  getStrategyList({
    bandType, bandId, page, pageSize,
  }) {
    return gameplusApi.request({
      url: '/community.GameToolAdminSrv/GetPageList',
      data: {
        bandType: String(bandType) || '',
        bandId: String(bandId) || '',
        page: parseInt(page, 10) || 1,
        pageSize: parseInt(pageSize, 10) || 20,
      },
      options: {
        errorHandler: false,
      },
    });
  },
  getStrategyL1List({
    pid, page, pageSize,
  }) {
    return gameplusApi.request({
      url: '/community.GameToolAdminSrv/GetL1List',
      data: {
        pid: parseInt(pid, 10) || 0,
        page: parseInt(page, 10) || 1,
        pageSize: parseInt(pageSize, 10) || 20,
      },
      options: {
        errorHandler: false,
      },
    });
  },
  getStrategyL2List({
    l1Id, page, pageSize,
  }) {
    return gameplusApi.request({
      url: '/community.GameToolAdminSrv/GetL2List',
      data: {
        l1Id: parseInt(l1Id, 10) || 0,
        page: parseInt(page, 10) || 1,
        pageSize: parseInt(pageSize, 10) || 20,
      },
      options: {
        errorHandler: false,
      },
    });
  },
  addToStrategy({
    groupId, contentId, pageId, l1Id, l2Id,
  }) {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/AddToStrategy',
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      data: {
        groupId: parseInt(groupId, 10) || 0,
        contentId: parseInt(contentId, 10) || 0,
        pageId: parseInt(pageId, 10) || 0,
        l1Id: parseInt(l1Id, 10) || 0,
        l2Id: parseInt(l2Id, 10) || 0,
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },

  MobileDeleteComment({ commentId }) {
    return gameplusApi.request({
      url: '/community.GroupManageSrv/MobileManageDeleteComment',
      headers: {
        'X-Odp-Destination-Service': 'community-group-manage-srv',
      },
      data: {
        comment_id: parseInt(commentId, 10),
      },
      options: {
        errorHandler: 'auto',
      },
    });
  },
};
