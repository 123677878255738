import manage from '@/api/manage';

export const MANAGER_STORE_MODULE_NAME = 'manager';

export const PERM_TAG = { // 权限列表
  groupBan: '/community.GroupManageSrv/BanUser', // 圈子内用户禁言
  allBan: '/community.BanSrv/BanUsers', // 全平台用户禁言
  choice: '/community.PostSrv/UpdatePostSync', // 加精
  offline: '/community.PostSrv/OfflinePost', // 下架
  online: '/community.PostSrv/OnlinePost', // 下架
  pantry: '/community.GroupManageSrv/ChangeGroup', // 移到茶水间
  top: '/community.PostSrv/UpdatePostSync', // 置顶
  announcement: '/community.PostSrv/UpdatePostSync', // 公告
  strategy: '/community.GroupManageSrv/AddToStrategy', // 攻略库
  sink: '/community.GroupManageSrv/DownContent', // 沉贴
  delete: '/community.GroupManageSrv/MobileManageDeleteComment', // 删除
};


const createState = () => ({
  allPerms: null,
  groupId: 0,
  perms: {},
  hasPerm: false,
});

// 设置权限信息
const setPerms = (state) => {
  if (state.allPerms && state.groupId) {
    const perms = {};
    Object.keys(PERM_TAG).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(PERM_TAG, key)) {
        const tag = PERM_TAG[key];
        if (key === 'allBan') {
          if (state.allPerms[tag]) { // 有全局禁用的权限
            state.hasPerm = true;
            perms[tag] = 1;
          }
        } else if (state.allPerms[tag] && state.allPerms[tag].groupIdList && (state.allPerms[tag].groupIdList.indexOf(String(state.groupId)) !== -1 || state.allPerms[tag].groupIdList.indexOf('0') !== -1)) {
          state.hasPerm = true;
          perms[tag] = 1;
        }
      }
    });
    state.perms = perms;
  }
};

export const managerStore = {
  namespaced: true,
  state: createState,
  mutations: {
    updatePerms(state, data) {
      state.allPerms = data.perms;
      setPerms(state);
    },
    setGroupId(state, groupId) {
      if (state.groupId !== groupId) {
        state.groupId = groupId;
        setPerms(state);
      }
    },
  },
  actions: {
    getPerms({ commit }) {
      manage.getUserPermission().then((result) => {
        commit('updatePerms', result);
      })
        .catch((error) => {
          this.$logger.error('getUserPermission', error);
        });
    },
  },
};
