<template>
  <div
    :style="containerStyle"
  >
    <div class="ui-image---status">
      <div
        v-if="loadStatus === 1"
        class="ui-image-fail"
      />
      <div
        v-else-if="loadStatus === 3"
        class="ui-image-blocked"
      />
      <img
        v-else-if="loadStatus === 2"
        :data-origin-url="src"
        :src="imageUrl"
        class="ui-image-img"
      >
      <div
        v-else
        class="ui-image-loading"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import clipImageUrl from './cdn-image-clip';
import { isServerRender } from '../../util/env';

export default {
  name: 'UiImage',
  props: {
    preStatus: {
      type: Number,
      default: 0,
    },
    paddingWidth: {
      type: [Number, String],
      default: 0,
    },
    paddingHeight: {
      type: [Number, String],
      default: 0,
    },
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      repeatTimes: 0,
      loadStatus: 0,
      realWidth: 0,
      realHeight: 0,
      containerWidth: 0,
    };
  },
  computed: {
    imageUrl() {
      let containerWidth = 800;
      if (!isServerRender) {
        containerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      }
      return clipImageUrl(this.src, containerWidth);
    },
    containerStyle() {
      return { paddingTop: this.paddingTop };
    },
    paddingTop() {
      return this.loadStatus === 2 ? `${((parseInt(this.realHeight, 0) / parseInt(this.realWidth, 0)) * 100).toFixed(2)}%`
        : `${((parseInt(this.paddingHeight, 0) / parseInt(this.paddingWidth, 0)) * 100).toFixed(2)}%`;
    },
  },
  mounted() {
    if (this.preStatus !== 2) {
      this.loadImage();
    } else {
      this.loadStatus = 2;
    }
  },
  methods: {
    loadImage(url) {
      const oImage = new Image();
      oImage.onload = () => {
        this.realHeight = oImage.height;
        this.realWidth = oImage.width;
        this.loadStatus = 2;
        this.$emit('loadComplete', this.imageUrl);
      };
      oImage.onerror = () => {
        if (this.repeatTimes === 0) {
          this.repeatTimes += 1;
          let httpUrl = this.imageUrl;
          if (/^tgcimg/.test(httpUrl)) {
            httpUrl = httpUrl.replace('tgcimg:', '');
          }
          axios.get(httpUrl)
            .then(() => {
              this.loadImage(httpUrl);
            })
            .catch((err) => {
              if (err.response && err.response.status === 403) {
                this.loadStatus = 3;
              } else {
                this.loadStatus = 1;
              }
            });
        } else {
          this.loadStatus = 1;
        }
      };
      oImage.src = url || this.imageUrl;
    },
  },
};
</script>

<style lang="scss" src="./scss/status.scss"></style>
