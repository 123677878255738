<template>
  <div
    v-if="groupOwnerType"
    class="ui-label-group-owner"
  >
    <div :class="groupOwnerType" />
  </div>
</template>
<script>
export default {
  props: {
    groupOwnerType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
  .ui-label-group-owner {
    .small {
      width: 36px;
      height: 14px;
      background-image: url("./img/label_small_group_owner.png");
      background-size: 100% 100%;
    }
    .big {
      width: 36px;
      height: 14px;
      background-image: url("./img/label_big_group_owner.png");
      background-size: 100% 100%;
    }
  }

</style>
