<template>
  <div
    ref="vote"
    v-report:exposure="{ operid: '1511000010201', canReport: canVoteExposureReport, ...voteReportData }"
    @click="handleClickVote"
    class="vote"
  >
    <div class="title-wrapper">
      <span class="title">
        {{ title }}
      </span>
      <span class="type-title">
        {{ typeTitle }}
      </span>
    </div>
    <template v-if="isApp">
      <vote-option-in-app
        v-for="(item, index) in choicesInfo"
        :key="`vote-option-in-app-${index}`"
        :index="index"
        :choice-info="item"
        :total-count="totalCount"
        :has-voted="hasVoted"
        :is-activity-overtime="isActivityOvertime"
        :vote-render-count="voteRenderCount"
        @clickVoteOption="handleClickVoteOption"
      />
    </template>
    <template v-else>
      <vote-option
        v-for="(item, index) in choicesInfo"
        :key="`vote-option-${index}`"
        :choice-info="item"
      />
    </template>
    <div class="detail">
      {{ detail }}
    </div>
    <div
      v-if="showButton"
      @click="handleClickButton"
      class="button"
    >
      {{ buttonText }}
    </div>
    <Dialog
      v-model="showCancelVoteDialog"
      :confirm="true"
      @confirm="handleCancelVote"
      cancel-text="我再想想"
      confirm-text="取消投票"
    >
      确认取消投票吗？
    </Dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import reportData from '@/api/report';
import voteApi from '@/api/vote';
import { getStrategy, screenYAxisScroll } from '@/util/util';
import LaunchApp from '@/util/launchApp';
import { checkEnv } from '@/util/browser-env';
import Dialog from '@/ui/dialog/index.vue';
import VoteOptionInApp from './component/vote-option-in-app/index.vue';
import VoteOption from './component/vote-option/index.vue';
import {
  VOTE_TYPE,
  VOTE_TYPE_TITLE,
  VOTER_STATUS,
  VOTE_STATUS,
  VOTE_BUTTON_TYPE,
  VOTE_BUTTON_TEXT,
  VOTE_SCROLL_TYPE,
  VOTE_COMMENT_TYPE,
} from './constants';

/** 投票组件(包含投票和取消投票网络请求的逻辑) */
export default {
  name: 'Vote',
  components: {
    Dialog,
    VoteOption,
    VoteOptionInApp,
  },
  props: {
    // message VoteInfo {
    //   uint64 id = 1 [(desc) = "投票id"];
    //   string title = 2 [(desc) = "投票标题"];
    //   VoteType type = 3 [(desc) = "投票类型"];
    //   repeated VoteChoiceInfo choices_info = 4 [(desc) = "投票选项"];
    //   int64 start_ts = 5 [(desc) = "开始时间"];
    //   int64 end_ts = 6 [(desc) = "结束时间"];
    //   uint64 voter_num = 7 [(desc) = "参与人数"];
    //   VoterStatus voter_status = 8 [(desc) = "投票人状态"];
    //   uint64 creator_id = 9 [(desc) = "投票创建人id"];
    //   "VoteStatus vote_status = 10 [(desc) = "投票状态"];
    // }
    //   前端自定义添加的数据上报数据字段reportData(包含groupId、contenttype、contentid)
    voteInfo: {
      type: Object,
      default: () => ({}),
    },
    voteScrollType: {
      type: Number,
      default: VOTE_SCROLL_TYPE.none,
    },
    voteCommentType: {
      type: Number,
      default: VOTE_COMMENT_TYPE.none,
    },
    voteRenderCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isApp: checkEnv().isApp,
      showCancelVoteDialog: false,
    };
  },
  computed: {
    canVoteExposureReport() {
      // 因为原来post-text的渲染时机，会导致vote组件不断被重新渲染，因此当渲染次数是第二次时，才进行曝光上报
      return this.voteRenderCount === 2;
    },
    title() {
      return this.voteInfo.title || '';
    },
    typeTitle() {
      return VOTE_TYPE_TITLE[this.voteInfo.type] || VOTE_TYPE_TITLE[VOTE_TYPE.unknown];
    },
    totalCount() {
      let totalCount = 0;
      this.choicesInfo.forEach((item) => {
        totalCount += Number(item.num);
      });
      return totalCount;
    },
    voterNum() {
      return Number(this.voteInfo.voterNum) || 0;
    },
    hasVoted() {
      return this.voteInfo.voterStatus === VOTER_STATUS.voted;
    },
    isActivityOvertime() {
      return this.voteInfo.voteStatus === VOTE_STATUS.end;
    },
    detail() {
      const endTime = this.voteInfo.endTs;
      const time = endTime && dayjs(endTime * 1000).format('M月D日 HH:mm');
      const timeInfo = (this.isActivityOvertime || !time) ? '投票已过期' : `${time}截止`;

      return `${this.voterNum}位玩家参与了投票 · ${timeInfo}`;
    },
    choicesInfo() {
      return this.voteInfo.choicesInfo || [];
    },
    chooseIndexList() {
      const list = [];
      this.choicesInfo.forEach((item, index) => {
        if (item.isChosen) {
          list.push(index);
        }
      });
      return list;
    },
    chooseNameList() {
      const list = [];
      this.chooseIndexList.forEach((item) => {
        const name = this.choicesInfo?.[item]?.choice?.name;
        list.push(name);
      });
      return list;
    },
    chooseCount() {
      return this.chooseIndexList.length;
    },
    isMultipleType() {
      return this.voteInfo?.type === VOTE_TYPE.multiple;
    },
    buttonType() {
      const strategyList = [{
        // 1.不在app内 或 活动过期，则不显示按钮
        condition: !this.isApp || this.isActivityOvertime,
        value: VOTE_BUTTON_TYPE.none,
      }, {
        // 2.单选尚未投票 或 多选尚未投票且选项为0，则不显示按钮
        condition: !this.hasVoted && (!this.isMultipleType || (this.isMultipleType && !this.chooseCount)),
        value: VOTE_BUTTON_TYPE.none,
      }, {
        // 3.多选尚未投票且选项不为0，则显示”确定投票“按钮
        condition: !this.hasVoted && this.isMultipleType && this.chooseCount,
        value: VOTE_BUTTON_TYPE.vote,
      }, {
        // 4.已投票，显示“我来说几句”按钮
        condition: this.hasVoted,
        value: VOTE_BUTTON_TYPE.comment,
      }];

      return getStrategy(strategyList, VOTE_BUTTON_TYPE.none);
    },
    showButton() {
      return this.buttonType !== VOTE_BUTTON_TYPE.none;
    },
    buttonText() {
      return VOTE_BUTTON_TEXT[this.buttonType];
    },
    voteReportData() {
      return {
        ext2: this.voteInfo?.voteStatus === VOTE_STATUS.end ? 0 : 1, // 是否投票结束（0:结束，1:未结束）
        ext3: Number(this.voteInfo?.choicesInfo?.length ?? 0), // 选项个数
        ext4: this.voteInfo?.type === VOTE_TYPE.single ? 0 : 1, // 单选多选（0:单选，1:多选）
        ext5: this.voteInfo?.choicesInfo?.[0]?.choice?.picUrl ? 1 : 0, // 选项是否有图（0:无，1:多有）
        ext6: this.voteInfo?.reportData?.groupId || 0, // 圈子id
        ext8: this.voteInfo?.id, // 投票id
        contenttype: this.voteInfo?.reportData?.contenttype, // 上报帖子id
        contentid: this.voteInfo?.reportData?.contentid, // 帖子类型
      };
    },
  },
  mounted() {
    this.handleEnterPageScrollInView();
    this.handleEnterPageProvokeComment();
  },
  methods: {
    handleEnterPageScrollInView() {
      const needToScrollInView = this.voteScrollType === VOTE_SCROLL_TYPE.inView;
      if (needToScrollInView) {
        this.handleScrollInView();
      }
    },
    handleEnterPageProvokeComment() {
      const needToProvokeComment = this.voteCommentType === VOTE_COMMENT_TYPE.provokeComment;
      if (needToProvokeComment) {
        this.handleClickCommentButton(false, true);
      }
    },
    handleClickVoteOption(index) {
      reportData({
        operid: '1511000010301',
        ...this.voteReportData,
      }, '内容详情页内投票组件点击参与');
      if (this.hasVoted) {
        if (this.chooseIndexList.includes(index)) {
          this.showCancelVoteDialog = true;
        }
        return;
      }

      if (!this.isMultipleType) {
        this.handleVoteSingleOption(index);
      } else {
        this.handleVoteMutilOption(index);
      }
    },
    getFormatVoteInfo(chooseIndexList) {
      const choicesInfo = [];
      this.choicesInfo.forEach((item, index) => {
        const isChosen = item?.isChosen;
        choicesInfo.push({
          ...item,
          isChosen: chooseIndexList.includes(index) ? !isChosen : isChosen, // 反选投票状态
        });
      });
      const voteInfo = {
        ...this.voteInfo,
        choicesInfo,
      };

      return voteInfo;
    },
    async handleVoteOption(voteChoices) {
      try {
        const voteInfo = await voteApi.postVote({
          id: this.voteInfo.id,
          voteChoices,
        });
        this.$emit('clickVoteOption', voteInfo);
        reportData({
          operid: '1511000010601',
          ...this.voteReportData,
        }, '内容详情页内投票组件投票成功');
      } catch (e) {
        this.$toast({ message: '投票失败', duration: 1000 });
      }
    },
    /** 单选投票 */
    handleVoteSingleOption(index) {
      this.$checkAuthDeco(this.handleVoteOption, [index]);
    },
    /** 多选投票 */
    handleVoteMutilOption(index) {
      const voteInfo = this.getFormatVoteInfo([index]);
      this.$emit('clickVoteOption', voteInfo);
    },
    handleCancelVote() {
      this.$checkAuthDeco(async () => {
        try {
          const voteInfo = await voteApi.postCancelVote({
            id: this.voteInfo.id,
          });
          this.$emit('clickVoteOption', voteInfo);
        } catch (e) {
          this.$toast({ message: '取消投票失败', duration: 1000 });
        }
      });
    },
    handleClickButton() {
      const strategyObj = {
        [VOTE_BUTTON_TYPE.vote]: this.handleClickVoteButton,
        [VOTE_BUTTON_TYPE.comment]: this.handleClickCommentButton,
      };
      const strategy = strategyObj[this.buttonType];
      if (strategy) {
        strategy();
      }
    },
    /** 点击多选题投票按钮 */
    handleClickVoteButton() {
      this.$checkAuthDeco(this.handleVoteOption, this.chooseIndexList);
    },
    handleClickCommentButton(needReportData = true, needDelayPostComment = false) {
      const commentPrefix = `我选${this.chooseNameList.join('、')}`;
      this.$emit('clickCommentButton', commentPrefix, needDelayPostComment);
      if (needReportData) {
        reportData({
          operid: '1511000010302',
          ...this.voteReportData,
        }, '内容详情页投票组件下点击我来说几句');
      }
    },
    handleScrollInView() {
      const voteBCR = this.$refs.vote.getBoundingClientRect();
      const voteTop = voteBCR.top || 0;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
      const headerOffset = clientWidth * (9 / 16); // 预留9/16的视频顶部高度
      const footerOffset = 100; // 底部距离偏差，防止组件只露出一点点的情况，没有滑动
      const isVoteInView = voteTop <= (clientHeight - headerOffset - footerOffset);

      if (!isVoteInView) {
        const scrollDistance = voteTop - headerOffset - footerOffset;
        screenYAxisScroll({ scrollDistance });
      }
    },
    handleClickVote() {
      if (!this.isApp) {
        // eslint-disable-next-line no-new
        new LaunchApp({
          schema: this.customSchema,
          channel: 'ShanxianOfficial',
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
