<template>
  <div
    class="com-post-text"
  >
    <div
      class="com-post-text-content"
    >
      <template v-if="channel === 1">
        <article
          id="com-post-text-content-penguin"
          key="com-post-text-content-penguin"
        />
      </template>
      <template v-else-if="channel === 2 || channel === 4">
        <pre class="com-post-text-pre">{{ content }}</pre>
        <ul
          v-if="pics && Array.isArray(pics.info)"
          class="com-post-text-pic"
        >
          <li
            v-for="(item, index) in pics.info"
            :key="index"
            class="com-post-text-pic-container"
          >
            <UIImage
              :padding-height="item.height"
              :padding-width="item.width"
              :src="item.url"
            />
          </li>
        </ul>
      </template>
      <template v-else-if="channel === 3 || channel === 6">
        <article
          id="com-post-text-content-author"
          key="com-post-text-content-author"
        />
      </template>
      <!-- channel === 8 app发布的富文本 -->
      <template v-else>
        <!-- eslint-disable -->
      <article
        key="com-post-text-content-rich"
        id="com-post-text-content-rich"
      />
    </template>
  </div>
  <div
    v-show="showVideoArea"
    class="com-post-text-video"
    :style="playerStyle"
  >
    <VideoPlayer
      v-show="showVideoPlayer"
      :play-info="playInfo"
      @videoCanPlay="onVideoCanPlayHandler"
    />
  </div>
  <PhotoSwipe ref="pswp"/>
</div>
</template>
<script>
import Vue from 'vue';
import xss, { filterXSS } from 'xss';
import PhotoSwipe from '@/ui/photo-swipe';
import VideoPlayer from '@/component/video-player/index.vue';
import Vote from '@/component/vote/index.vue';
import { VOTE_SCROLL_TYPE, VOTE_COMMENT_TYPE } from '@/component/vote/constants';
import UIImage from '@/ui/image/status.vue';
import uniOpenUrl from '@/util/uni-open-url';
import { logger } from '@/util/logger';
import { isServerRender } from '@/util/env';
import { transferImgTag } from './post-text-util';

const OImage = Vue.extend(UIImage);
const OVote = Vue.extend(Vote);

export default {
  name: 'ComPostText',
  components: {
    VideoPlayer, UIImage, PhotoSwipe,
  },
  props: {
    channel: {
      type: Number, // 0 脏数据 直接渲染富文本即可 1 企鹅号 2 app 3 作者端 4 系统生成，样式跟app一致, 6 来自tgl
      default: 1,
    },
    content: {
      type: String,
      default: '',
    },
    pics: {
      type: Object,
      default() {
        return null;
      },
    },
    postMain: {
      type: String,
      default: '',
    },
    /** 投票信息 */
    voteInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      penguinContent: '', // 企鹅号数据目前不做太多解析规则
      richContent: '', // 默认直接展示的富文本
      playInfo: {},
      playerStyle: {},
      showVideoArea: false,
      showVideoPlayer: false,
      curVideo: null,
      imagePreviewList: [],
      imagePreviewEventFlag: false,
      urlCacheArr: [],
      gallery: null, // 查看大图 PhotoSwipe 实例
      voteRenderCount: 0, // 投票组件的渲染次数（用于控制组件曝光数据上报、以及防止多次弹起评论框等）
    };
  },
  computed: {
    isApp() {
      return this.$store.state.isApp;
    },
  },
  watch: {
    channel() {
      this.renderContent();
    },
    content() {
      // 会多次拉取内容，变化后需要重新渲染
      this.renderContent();
    },
    voteInfo() {
      this.renderContent();
    },
  },
  mounted() {
    this.renderContent();
  },
  beforeDestroy() {
    this.removeImagePreview();
  },
  methods: {
    preloadImages() {
      // 关闭，不要预先加载图片
      // if (isServerRender) return;
      // this.imagePreviewList.forEach((item, index) => {
      //   if (item.width === 0 || item.height === 0) {
      //     const img = new Image();
      //     img.onload = () => { // will get size after load
      //       this.$set(this.imagePreviewList, index, {
      //         url: item.url,
      //         result: item.result,
      //         width: img.width,
      //         height: img.height,
      //       });
      //       // console.log('this.imagePreviewList', this.imagePreviewList);
      //     };
      //     img.src = item.url; // let's download image
      //   }
      // });
    },
    addLoadPicture({ url } = {}) {
      this.urlCacheArr.push(url);
    },
    renderContent() {
      logger.info('=>>>>>>>>>>>>>>>>>channel：', this.channel);
      if (this.channel === 1) {
        // 企鹅号
        this.renderPenguin();
      } else if (this.channel === 2 || this.channel === 4) {
        // app
        this.renderApp();
        // 直接在template做处理了
      } else if (this.channel === 3) {
        // 作者端
        this.renderAuthor();
      } else if (this.channel === 6) {
        // tgl,处理跟作者端一致
        this.renderAuthor();
      } else if (this.channel === 8) {
        // 来自APP端的图文混排，当做富文本处理
        this.renderRich();
      } else {
        // 默认按照企鹅号来解析
        this.renderRich();
      }
    },
    onVideoCanPlayHandler() {
      this.curVideo.classList.remove('loading');
      setTimeout(() => {
        this.showVideoPlayer = true;
        this.curVideo.classList.add('playing');
      }, 100);
    },
    renderApp() {
      if (this.pics && Array.isArray(this.pics.info)) {
        this.imagePreviewList = this.pics.info.slice();
      } else {
        this.imagePreviewList = [];
      }
      this.preloadImages();
      this.$nextTick(() => {
        this.addImagePreview();
      });
    },
    getImagePreviewList(imgArr) {
      return imgArr.map((item) => {
        let w = 0;
        let h = 0;
        if (this.pics && Array.isArray(this.pics.info)) {
          this.pics.info.some((item1) => {
            if (item1.url === item) {
              w = item1.width;
              h = item1.height;
              return true;
            }
            return undefined;
          });
        }
        return {
          width: w,
          height: h,
          result: 0,
          url: item,
        };
      });
    },
    /**
     * @deprecated 废弃，使用 post-test-util里的函数
     */
    transferImgTag(htmlStr) {
      // console.log('transferImgTag before', htmlStr);
      // console.log('transferImgTag pics', JSON.stringify(this.pics.info));
      const result = htmlStr
        .replace(/(\r\n|\n|\r)/g, '<br />')
        .replace(/<(p|div).*?>(.*?)<\/(p|div)>/g, (match, $1, $2) => {
          if (/<img.*?src="(.*?)".*?\/>/.test($2)) {
            const tempObj = {};
            $2.replace(/(src|width|height)=["']?(.*?)["']?[\s+>]/g, (match1, $11, $21) => {
              tempObj[$11] = $21;
              return match1;
            });
            const imgSrc = tempObj.src || '';
            let w = tempObj.width || 0;
            let h = tempObj.height || 0;
            if (!imgSrc) return '';
            if ((w <= 0 || h <= 0) && this.pics && Array.isArray(this.pics.info)) {
              const o = this.pics.info.filter(item => item.url === imgSrc)[0];
              if (o && Number(o.width) > 0 && Number(o.height) > 0) {
                w = o.width;
                h = o.height;
              }
            }
            if (w > 0 && h > 0) {
              return `<div class="com-post-text-pic-container" data-width="${w}" data-height="${h}" data-url="${imgSrc}"><img class="image-preview" src="${imgSrc}" /></div>`;
            }
            return `<div class="com-post-text-content-img-container"><img class="image-preview" src="${imgSrc}" /></div>`;
          }
          return `<p class="com-post-text-content-p">${$2}</p>`;
        });
      // console.log('transferImgTag after', result);
      return result;
    },
    replaceImageTag(dom) {
      const picList = dom.querySelectorAll('.com-post-text-pic-container');
      Array.prototype.forEach.call(picList, (item) => {
        const { url, width, height } = item.dataset;
        // const status = this.urlCacheArr.indexOf(url) > -1 ? 2 : 0;
        const status = this.urlCacheArr.some(cUrl => cUrl.indexOf(url) > -1) ? 2 : 0;
        const iImage = new OImage({
          propsData: {
            preStatus: status,
            paddingWidth: width,
            paddingHeight: height,
            src: url,
          },
        });
        iImage.$on('loadComplete', (url1) => {
          // console.log(url1);
          this.addLoadPicture({
            url: url1,
          });
        });
        const imgChild = item.getElementsByClassName('image-preview');
        if (imgChild && imgChild[0]) {
          iImage.$mount(imgChild[0]);
        }
      });
    },
    renderAuthor() {
      // const vm = this;
      const imgArr = [];
      /* eslint-disable-next-line */
      // const test = '</style><script>alert(\"hack\")<\/script><style>';
      // eslint-disable-next-line
      let html = filterXSS(this.content, {
        onIgnoreTagAttr(tag, name, value) {
          if (name.substr(0, 5) === 'data-' || (name === 'style')) {
            // 通过内置的escapeAttrValue函数来对属性值进行转义
            return `${name}="${xss.escapeAttrValue(value)}"`;
          }
          return undefined;
        },
        onTagAttr(tag, name, value) {
          if (tag === 'img') {
            // console.log(name);
            // console.log(value);
            if (name === 'src') {
              imgArr.push(xss.friendlyAttrValue(value));
            }
          }
          if (tag === 'a' && name === 'href') {
            if (/^tgc/.test(value)) {
              return `${name}="${value}"`;
            }
          }
          // 不返回任何值，表示还是按照默认的方法处理
          return undefined;
        },
        // onTag (tag, h, options) {
        //   if (tag === 'img') {
        //     console.log(tag);
        //     console.log(xss.friendlyAttrValue(h));
        //     console.log(options);
        //     return `<div class="com-post-text-content-img-container">${h}</div>`;
        //   }
        //   return undefined;
        // },
      });
      this.imagePreviewList = this.getImagePreviewList(imgArr);
      this.preloadImages();
      html = transferImgTag(html, this.pics?.info);
      const oDiv = document.createElement('div');
      oDiv.setAttribute('id', 'com-post-text-content-author-inner');
      oDiv.innerHTML = html;
      // 处理视频部分 暂时不处理了
      // const oVideoList = oDiv.querySelectorAll('[data-tag="video"]');
      // if (oVideoList && oVideoList.length > 0) {
      //   this.showVideoArea = true;
      //   oVideoList.forEach((item) => {
      //     item.classList.add('com-post-text-content-video');
      //     item.style.backgroundImage = `url(${item.dataset.album})`;
      //     const itemPlayBtn = document.createElement('div');
      //     itemPlayBtn.classList.add('com-post-text-content-video-play');
      //     itemPlayBtn.dataset.appId = item.dataset.appId;
      //     itemPlayBtn.dataset.fileId = item.dataset.fileId;
      //     item.appendChild(itemPlayBtn);
      //     const itemLoading = document.createElement('div');
      //     itemLoading.className = 'com-post-text-content-video-loading';
      //     item.appendChild(itemLoading);
      //     itemPlayBtn.addEventListener('click', function fn () {
      //       Array.prototype.forEach.call(this.parentNode.parentNode.childNodes, (item1) => {
      //         if (item1.dataset && item1.dataset.tag === 'video') {
      //           item1.classList.remove('playing', 'loading');
      //         }
      //       });
      //       vm.showVideoPlayer = false;
      //       vm.curVideo = this.parentNode;
      //       vm.curVideo.classList.add('loading');
      //       vm.playerStyle = {
      //         top: `${this.parentNode.offsetTop + 1}px`,
      //       };
      //       vm.playInfo = {
      //         type: 'txy',
      //         appId: this.dataset.appId,
      //         fileId: this.dataset.fileId,
      //         autoplay: true,
      //         bigPlayButton: false,
      //         posterImage: false,
      //       };
      //     });
      //   });
      // }
      // 处理链接部分，代理a标签跳转行为
      const aList = oDiv.querySelectorAll('a');
      Array.prototype.forEach.call(aList, (a) => {
        const href = a.getAttribute('href');
        // eslint-disable-next-line no-param-reassign
        a.dataset.href = href;
        // eslint-disable-next-line
        a.setAttribute('href', 'javascript:;');
        a.addEventListener('click', () => {
          uniOpenUrl(href);
        });
      });
      this.replaceImageTag(oDiv);
      this.$nextTick(() => {
        // channel 字段变更的话渲染要滞后
        const parentEle = document.getElementById('com-post-text-content-author');
        const childEle = document.getElementById('com-post-text-content-author-inner');
        if (childEle) {
          parentEle.replaceChild(
            oDiv,
            childEle
          );
        } else {
          parentEle.appendChild(oDiv);
        }
        this.addImagePreview();
        this.renderVoteComponent();
      });
    },
    /** 投票组件替换 */
    renderVoteComponent() {
      const { voteInfo } = this;
      const voteElement = document.querySelector('span[data-shanxian-component=\'vote\']');
      if (!voteInfo || !voteElement) {
        return;
      }

      const { voteScrollType, voteCommentType } = this.$route.query;
      this.voteRenderCount += 1;
      // 进入页面自动触发评论框，只触发1次，且在第2次渲染时触发，避免userInfo数据尚未获取就触发
      const formatVoteCommentType = this.voteRenderCount === 2 ? voteCommentType : VOTE_COMMENT_TYPE.none;
      const oVote = new OVote({
        propsData: {
          voteInfo,
          voteRenderCount: this.voteRenderCount,
          voteScrollType: Number(voteScrollType || VOTE_SCROLL_TYPE.none),
          voteCommentType: Number(formatVoteCommentType || VOTE_COMMENT_TYPE.none),
        },
      });
      oVote.$on('clickCommentButton', (commentPrefix, needDelayPostComment) => {
        this.$emit('clickCommentButton', commentPrefix, needDelayPostComment);
      });
      oVote.$on('clickVoteOption', (formatVoteInfo) => {
        this.$emit('clickVoteOption', formatVoteInfo);
      });
      oVote.$mount(voteElement);
    },
    renderPenguin() {
      // 目前企鹅号数据不做解析直接展示
      const imgArr = [];
      let html = filterXSS(this.content, {
        onTagAttr(tag, name, value) {
          if (tag === 'img' && name === 'src') {
            // 使用内置的friendlyAttrValue函数来对属性值进行转义，可将&lt;这类的实体标记转换成打印字符<
            imgArr.push(xss.friendlyAttrValue(value));
          }
          // 不返回任何值，表示还是按照默认的方法处理
        },
      });
      html = transferImgTag(html, this.pics?.info);
      const oDiv = document.createElement('div');
      oDiv.setAttribute('id', 'com-post-text-content-penguin-inner');
      oDiv.innerHTML = html;
      this.replaceImageTag(oDiv);
      this.imagePreviewList = this.getImagePreviewList(imgArr);
      this.preloadImages();
      this.$nextTick(() => {
        // channel 字段变更的话渲染要滞后
        const parentEle = document.getElementById('com-post-text-content-penguin');
        const childEle = document.getElementById('com-post-text-content-penguin-inner');
        if (childEle) {
          parentEle.replaceChild(
            oDiv,
            childEle
          );
        } else {
          parentEle.appendChild(oDiv);
        }
        this.addImagePreview();
      });
    },
    renderRich() {
      // 目前企鹅号数据不做解析直接展示
      const imgArr = [];
      let html = filterXSS(this.content, {
        onTagAttr(tag, name, value) {
          if (tag === 'img' && name === 'src') {
            // 使用内置的friendlyAttrValue函数来对属性值进行转义，可将&lt;这类的实体标记转换成打印字符<
            imgArr.push(xss.friendlyAttrValue(value));
          }
          // 不返回任何值，表示还是按照默认的方法处理
        },
      });
      html = transferImgTag(html, this.pics?.info);
      const oDiv = document.createElement('div');
      oDiv.setAttribute('id', 'com-post-text-content-rich-inner');
      oDiv.innerHTML = html;
      this.replaceImageTag(oDiv);
      this.imagePreviewList = this.getImagePreviewList(imgArr);
      this.preloadImages();
      this.$nextTick(() => {
        // channel 字段变更的话渲染要滞后
        const parentEle = document.getElementById('com-post-text-content-rich');
        const childEle = document.getElementById('com-post-text-content-rich-inner');
        if (childEle) {
          parentEle.replaceChild(
            oDiv,
            childEle
          );
        } else {
          parentEle.appendChild(oDiv);
        }
        this.addImagePreview();
      });
    },
    // 图片放大查看器
    addImagePreview() {
      if (isServerRender) return;
      if (this.postMain === 'video' || this.imagePreviewEventFlag || !this.pics || !this.pics.info || this.pics.info.length === 0) return false;
      this.imagePreviewEventFlag = true;
      document.getElementsByClassName('com-post-text-content')[0].addEventListener('click', (e) => {
        const imgArr = this.imagePreviewList;
        if (e.target.nodeName === 'IMG') {
          const url = e.target.dataset.originUrl || e.target.src;
          if (url) {
            logger.info('showPhotoSwipe', {
              url,
              images: imgArr,
            });
            this.$refs.pswp.showPhotoSwipe({
              url,
              images: imgArr,
            });
          }
        }
      });
      return undefined;
    },
    removeImagePreview() {
      if (this.gallery) {
        this.gallery.destroy();
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
