<template>
  <div
    v-bem-ssr="true"
    class="com-post-title"
  >
    <template v-for="(flagIndex, index) in currentFlagList">
      <span
        :key="index"
        :class="['com-post-title-label', `title-flag-${flagIndex}`]"
      />
    </template>
    <span class="com-post-title-content">{{ title }}</span>
  </div>
</template>
<script>
import { isBitTrue } from '../../util/util';

export default {
  name: 'ComPostTitle',
  props: {
    title: {
      type: [String, Number],
      default: '',
    },
    flag: {
      type: [String, Number],
      default: 2,
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allFlagList: [1, 3, 5, 13], // 1-官，3-精，5-顶，13-奖
    };
  },
  computed: {
    currentFlagList() {
      const flag = Number(this.flag);
      return [].concat(this.allFlagList).sort((a, b) => b - a)
        .filter(index => isBitTrue(flag, index));
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
