<template>
  <div v-bem>
    <div
      v-show="isShow"
      v-bem:shadow
      @touchmove.stop="preventDefault"
    />
    <div
      v-show="isShow"
      v-bem:content
    >
      <div
        v-bem:title
        @touchmove.stop="preventDefault"
      >
        内容管理
      </div>
      <div v-bem:divider />

      <div v-bem:list-warp>
        <ul v-bem:list>
          <li
            v-for="item in opList"
            :key="item.type"
            v-bem:item
            @click="clickOp(item.type)"
          >
            <img
              v-bem:item-img
              :src="item.icon"
            >
            <span v-bem:item-name>{{ item.name }}</span>
          </li>
        </ul>
      </div>


      <div v-bem:grey-divider />
      <div
        v-bem:cancel=""
        @click="clickCancel"
        @touchmove.stop="preventDefault"
      >
        <span>取消</span>
      </div>
    </div>

    <OfflineDialog
      v-if="hasOfflinePerm"
      v-model="isShowOfflineDialog"
      :post-info="postInfo"
      :page-type="pageType"
      :report-data="reportData"
      @update="update"
    />
    <BanUserDialog
      v-if="hasBanPerm"
      v-model="isShowBanUserDialog"
      :type="banType"
      :post-info="postInfo"
      :page-type="pageType"
      :creator-info="creatorInfo"
      :report-data="reportData"
      @update="update"
    />
    <StrategyLibDialog
      v-if="hasStrategyPerm"
      v-model="isShowStrategyLibDialog"
      :report-data="reportData"
      :post-info="postInfo"
      :page-type="pageType"
    />
  </div>
</template>

<script>
import { isBitTrue } from '@/util/util';
import { checkEnv } from '@/util/browser-env';
import OfflineDialog from '../offline-dialog/index.vue';
import BanUserDialog from '../ban-user-dialog/index.vue';
import StrategyLibDialog from '../strategy-lib-dialog/index.vue';
import { managerStore, MANAGER_STORE_MODULE_NAME, PERM_TAG } from './store';
import { mapState } from 'vuex';
import manage from '@/api/manage';
import jsbridge from '@/jsbridge';
import { logger } from '@/util/logger';
import dialog from '@/ui/dialog/index';
import cookie from 'js-cookie';
import reportApi from '@/api/report';

const opMap = {
  choice: {
    icon: require('./img/icon_94_jinghua.png'),
    type: 'choice',
    name: '加精内容',
    ext1: '1',
  },
  cancelChoice: {
    icon: require('./img/icon_94_jinghuano.png'),
    type: 'cancelChoice',
    name: '取消加精',
    ext1: '2',
  },
  online: {
    icon: require('./img/icon_94_shangjia.png'),
    type: 'online',
    name: '恢复上架',
    ext1: '4',
  },
  offline: {
    icon: require('./img/icon_94_xiajia.png'),
    type: 'offline',
    name: '下架内容',
    ext1: '3',
  },
  pantry: {
    icon: require('./img/icon_94_move.png'),
    type: 'pantry',
    name: '移到茶水间',
    ext1: '5',
  },
  // ext1:按钮名称（1:加精,2:取消加精,3:下架,4:恢复上架,5:移到茶水间,6:添加话题,7:移除话题,8:更改话题,9:加置顶,10:取消置顶,
  // 11:加公告,12:取消公告,13:加入攻略,14:移出攻略库,15:内容沉底,16:取消沉底,17:发布者当前圈子禁言,18:发布者当前圈子取消禁言,19:发布者全平台禁言,20:发布者取消全平台禁言)
  ban: {
    icon: require('./img/icon_94_jinyan.png'),
    type: 'ban',
    name: '禁言用户',
  },
  cancelBan: {
    icon: require('./img/icon_94_jingyanno.png'),
    type: 'cancelBan',
    name: '解除禁言',
  },
  top: {
    icon: require('./img/icon_94_top.png'),
    type: 'top',
    name: '置顶内容',
    ext1: '9',
  },
  cancelTop: {
    icon: require('./img/icon_94_topno.png'),
    type: 'cancelTop',
    name: '取消置顶',
    ext1: '10',
  },
  announcement: {
    icon: require('./img/icon_94_gonggaoadd.png'),
    type: 'announcement',
    name: '加入公告',
    ext1: '11',
  },
  cancelAnnouncement: {
    icon: require('./img/icon_94_gonggaono.png'),
    type: 'cancelAnnouncement',
    name: '移出公告',
    ext1: '12',
  },
  strategy: {
    icon: require('./img/icon_94_gonglueadd.png'),
    type: 'strategy',
    name: '加入攻略库',
    ext1: '13',
  },
  cancelStrategy: {
    icon: require('./img/icon_94_gonglueno.png'),
    type: 'cancelStrategy',
    name: '移出攻略库',
  },
  sink: {
    icon: require('./img/icon_94_chentie.png'),
    type: 'sink',
    name: '沉底内容',
    ext1: '15',
  },
  cancelSink: {
    icon: require('./img/icon_94_chentieno.png'),
    type: 'cancelSink',
    name: '取消沉底',
    ext1: '16',
  },
};

const opIcon = {
  ban: require('./img/icon-44-ban.png'),
  cancelBan: require('./img/icon-44-cancel-ban.png'),
};

export default {
  name: 'ComPostManageDialog',
  components: {
    OfflineDialog,
    BanUserDialog,
    StrategyLibDialog,
  },
  model: {
    prop: 'isShow',
    event: 'change',
  },
  props: {
    isShow: Boolean,
    postInfo: {
      type: Object,
      default: () => ({}),
    },
    creatorInfo: {
      type: Object,
      default: () => ({}),
    },
    groupInfo: {
      type: Object,
      default: () => ({}),
    },
    pageType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowOfflineDialog: false, // 下架弹窗
      isShowBanUserDialog: false, // 禁言用户
      isShowStrategyLibDialog: false, // 加入攻略库弹窗
      banType: 'group', // all 、group
    };
  },
  computed: {
    ...mapState({
      perms: state => state.manager?.perms,
    }),
    reportCommonInfo() {
      const obj = {};
      if (this.postInfo) {
        // const source = String(this.postInfo.channel);
        Object.assign(obj, {
          contentid: String(this.postInfo.id),
          contentname: String(this.postInfo.title),
          ext6: String(this.postInfo.groupId),
        });
      }
      if (this.creatorInfo) {
        Object.assign(obj, {
          authorname: String(this.creatorInfo.nickName),
          authorid: String(this.creatorInfo.uid),
          authortype: String(this.creatorInfo.accountType),
        });
      }
      return obj;
    },
    opList() {
      const list = [];
      if (this.postInfo) {
        this.addChoiceOp(list); // 加精
        this.addOfflineOp(list); // 上下架
        this.addPantryOp(list); // 移到茶水间
        this.addBanOp(list); // 禁言
        this.addTopOp(list); // 置顶
        this.addAnnouncementOp(list); // 公告
        this.addStrategyOp(list); // 攻略库
        this.addSinkOp(list);// 沉贴
      }
      return list;
    },
    flag() {
      return this.postInfo?.flag || 0;
    },
    hasOfflinePerm() {
      return this.perms && this.perms[PERM_TAG.offline];
    },
    hasGroupBanPerm() {
      return this.perms && this.perms[PERM_TAG.groupBan];
    },
    hasAllBanPerm() {
      return this.perms && this.perms[PERM_TAG.allBan];
    },
    hasBanPerm() {
      return this.perms && (this.perms[PERM_TAG.allBan] || this.perms[PERM_TAG.groupBan]);
    },
    hasStrategyPerm() {
      return this.perms && this.perms[PERM_TAG.strategy];
    },
    isAllBan() { // 是否被全平台禁言
      return parseInt(this.creatorInfo?.status, 10) === 4;
    },
    isGroupBan() { // 是否被本圈子禁言
      const isHaveGroupIdAddInfo = this.postInfo?.groupId && this.creatorInfo?.forbiddenInfo?.groupInfo;
      return isHaveGroupIdAddInfo && this.creatorInfo.forbiddenInfo.groupInfo[this.postInfo?.groupId];
    },
    banTitle() {
      const groupList = this.banGroupList;

      if (groupList.length > 0) {
        return `该用户在「${groupList[0].groupName}」${groupList.length > 1 ? `等${groupList.length}个` : ''}圈子被禁言`;
      }
      return '请选择禁言类型';
    },
    banGroupList() {
      const groupList = [];
      if (this.creatorInfo?.forbiddenInfo?.groupInfo) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.creatorInfo.forbiddenInfo.groupInfo) {
          if (Object.prototype.hasOwnProperty.call(this.creatorInfo.forbiddenInfo.groupInfo, key)) {
            groupList.push({ groupId: key, ...this.creatorInfo.forbiddenInfo.groupInfo[key] });
          }
        }
      }
      return groupList;
    },
  },
  watch: {
    postInfo(value) {
      if (value?.groupId) {
        this.$store.commit('manager/setGroupId', value?.groupId);
      }
    },
    isShow(value) {
      if (value) {
        this.isShowOfflineDialog = false;
        this.isShowBanUserDialog = false;
        this.isShowStrategyLibDialog = false;
      }
    },
    isShowBanUserDialog(value) {
      if (value) {
        this.reportData({ operid: '1501000830302', ext1: this.banType === 'all' ? '19' : '17' }, '内容详情页管理操作入口点击');
      }
    },
  },

  mounted() {
    this.$store.registerModule(MANAGER_STORE_MODULE_NAME, managerStore);
    this.$store.dispatch('manager/getPerms');
    if (this.postInfo?.groupId) {
      this.$store.commit('manager/setGroupId', this.postInfo?.groupId);
    }
  },
  methods: {
    reportData(params, desc) {
      reportApi({ ...this.reportCommonInfo, ...params }, desc);
    },
    preventDefault(event) {
      event.preventDefault();
      return false;
    },
    update() {
      this.$emit('update');
    },
    clickCancel() {
      this.$emit('change', false);
    },
    clickOp(type) {
      this.$emit('change', false);
      switch (type) {
        case 'offline':
          this.isShowOfflineDialog = true;
          break;
        case 'ban':
        case 'cancelBan':
          this.handleBan(type);
          break;
        case 'choice':
          this.handleChoice(false);
          break;
        case 'cancelChoice':
          this.handleChoice(true);
          break;
        case 'pantry':
          this.handlePantry();
          break;
        case 'top':
          this.handleTop();
          break;
        case 'cancelTop':
          this.cancelTop();
          break;
        case 'strategy':
          this.handleStrategy();
          break;
        case 'announcement':
          this.handleAnnouncement();
          break;
        case 'cancelAnnouncement':
          this.cancelAnnouncement();
          break;
        case 'sink':
          this.handleSink(false);
          break;
        case 'cancelSink':
          this.handleSink(true);
          break;
        default:
      }

      if (opMap[type] && opMap[type].ext1) {
        this.reportData({ operid: '1501000830302', ext1: opMap[type].ext1 }, '内容详情页管理操作入口点击');
      }
    },
    handleSink(isCancel) {
      const confirmDialog = dialog({
        confirm: true,
        confirmText: '确认',
        cancelText: '取消',
        // eslint-disable-next-line no-confusing-arrow
        content: () => (isCancel ? '是否取消沉底？' : '是否沉底该内容？沉底后该内容不会再被回复顶起'),
        onConfirm: () => {
          confirmDialog.close();
          const loadingStatus = this.$loading({
            message: '操作中...',
          });

          manage.downContent({
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
            isCancel,
          }).then(() => {
            loadingStatus.close();
            this.$toast('操作成功');
            this.update();
            this.reportData({ operid: '1501000830601', ext1: isCancel ? opMap.cancelSink.ext1 : opMap.sink.ext1 }, '内容详情页管理操作入口点击');
          })
            .catch(() => {
              loadingStatus.close();
            });
        },
        onCancel: () => {
          confirmDialog.close();
        },
      });
    },
    handleTop() {
      jsbridge.ui.selectDate({
        title: '请选择置顶截止时间',
        confirmText: '确定',
        startDate: Date.now(),
        endDate: Date.now() + 60 * 60 * 1000 * 24 * 365 * 10,
        initDate: Date.now() + 60 * 60 * 1000 * 24,
        desc: '将在当天 23:59:59 自动取消置顶',
      }).then((res) => {
        // console.log(`selectDate: ${JSON.stringify(res)}`);
        logger.log(`selectDate: ${JSON.stringify(res)}`);
        if (res && res.result === 0 && res.data && res.data.date) {
          const loadingStatus = this.$loading({
            message: '操作中...',
          });
          manage.topContent({
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
            endTime: res.data.date,
            isCancel: false,
          }).then(() => {
            loadingStatus.close();
            this.$toast('操作成功');
            this.update();
            this.reportData({ operid: '1501000830601', ext1: opMap.top.ext1 }, '内容详情页管理操作成功');
          })
            .catch(() => {
              loadingStatus.close();
            });
        }
      });
    },
    cancelTop() {
      const confirmDialog = dialog({
        confirm: true,
        confirmText: '确认',
        cancelText: '取消',
        content: () => '是否取消置顶？',
        onConfirm: () => {
          confirmDialog.close();
          const loadingStatus = this.$loading({
            message: '操作中...',
          });
          manage.topContent({
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
            isCancel: true,
          }).then(() => {
            this.$toast('操作成功');
            loadingStatus.close();
            this.update();
            this.reportData({ operid: '1501000830601', ext1: opMap.cancelTop.ext1 }, '内容详情页管理操作成功');
          })
            .catch(() => {
              loadingStatus.close();
            });
        },
        onCancel: () => {
          confirmDialog.close();
        },
      });
    },
    handleAnnouncement() {
      jsbridge.ui.selectDate({
        title: '请选择公共截止时间',
        confirmText: '确定',
        startDate: Date.now(),
        endDate: Date.now() + 60 * 60 * 1000 * 24 * 365 * 10,
        initDate: Date.now() + 60 * 60 * 1000 * 24,
        desc: '将在当天 23:59:59 自动移出公告',
      }).then((res) => {
        // console.log(`selectDate: ${JSON.stringify(res)}`);
        logger.log(`selectDate: ${JSON.stringify(res)}`);
        if (res && res.result === 0 && res.data && res.data.date) {
          const loadingStatus = this.$loading({
            message: '操作中...',
          });
          manage.addToNotice({
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
            endTime: res.data.date,
            isCancel: false,
          }).then(() => {
            loadingStatus.close();
            this.$toast('操作成功');
            this.update();
            this.reportData({ operid: '1501000830601', ext1: opMap.announcement.ext1 }, '内容详情页管理操作成功');
          })
            .catch(() => {
              loadingStatus.close();
            });
        }
      });
    },
    cancelAnnouncement() {
      const confirmDialog = dialog({
        confirm: true,
        confirmText: '确认',
        cancelText: '取消',
        content: () => '是否取消公告？',
        onConfirm: () => {
          confirmDialog.close();
          const loadingStatus = this.$loading({
            message: '操作中...',
          });
          manage.addToNotice({
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
            isCancel: true,
          }).then(() => {
            this.$toast('操作成功');
            loadingStatus.close();
            this.update();
            this.reportData({ operid: '1501000830601', ext1: opMap.cancelAnnouncement.ext1 }, '内容详情页管理操作成功');
          })
            .catch(() => {
              loadingStatus.close();
            });
        },
        onCancel: () => {
          confirmDialog.close();
        },
      });
    },
    handleBan(type) {
      const myUid = cookie.get('uid');
      if (this.creatorInfo.uid === myUid) {
        this.$toast(type === 'ban' ? '不可对自己禁言' : '不可对自己解禁');
        return;
      }

      if (this.isAllBan) { // 用户被全平台禁言
        if (this.hasAllBanPerm) {
          this.$showActionSheet({
            title: '选择禁言类型',
            item: [{ name: '解除全平台禁言', icon: opIcon.cancelBan }],
            callback: (res) => {
              // console.log('$showActionSheet', res);
              if (res && res.ret === 0 && res.index === 0) {
                this.cancelBan(true);
              }
            },
          });
        } else {
          this.$showActionSheet({
            title: '选择禁言类型',
            desc: '该用户全平台禁言中\n'
                + '需要高级权限管理员解除',
            callback: () => {
              // console.log(res);
            },
          });
        }
      } else if (this.isGroupBan) { // 被当前圈子禁言
        if (this.hasAllBanPerm) {
          const opItem = [
            { name: '全平台禁言', icon: opIcon.ban },
            { name: '解除全部禁言', icon: opIcon.cancelBan },
          ];

          if (this.hasGroupBanPerm) {
            opItem.push({
              name: '解除本圈内禁言', icon: opIcon.cancelBan,
            });
          }
          this.$showActionSheet({
            title: this.banTitle,
            item: opItem,
            callback: (res) => {
              // console.log(res);
              if (res.ret === 0) {
                switch (res.index) {
                  case 0:
                    this.banType = 'all';
                    this.isShowBanUserDialog = true;
                    break;
                  case 1:
                    this.cancelBan(true);
                    break;
                  case 2:
                    this.cancelBan(false);
                    break;
                  default:
                }
              }
            },
          });
        } else {
          const opItem = [];
          if (this.hasAllBanPerm) {
            opItem.push({ name: '全平台禁言', icon: opIcon.ban, type: 'allBan' });
          }
          if (this.hasGroupBanPerm) {
            opItem.push({ name: '解除本圈内禁言', icon: opIcon.cancelBan, type: 'cancelBan' });
          }
          if (this.hasAllBanPerm && this.banGroupList.length > 0) { // 被其他圈子禁言时
            opItem.push({ name: '解除全部禁言', icon: opIcon.ban, type: 'cancelAllBan' });
          }
          this.$showActionSheet({
            title: this.banTitle,
            item: opItem,
            callback: (res) => {
              // console.log(res);
              if (res.ret === 0) {
                const op = opItem[res.index] || {};
                if (op.type === 'cancelBan') {
                  this.cancelBan(false);
                } else if (op.type === 'cancelAllBan') {
                  this.cancelBan(true);
                } else {
                  this.banType = 'all';
                  this.isShowBanUserDialog = true;
                }
              }
            },
          });
        }
      } else {
        const opItem = [];

        if (this.hasAllBanPerm) {
          opItem.push({ name: '全平台禁言', icon: opIcon.ban, type: 'allBan' });
        }
        if (this.hasGroupBanPerm) {
          opItem.push({ name: '本圈内禁言', icon: opIcon.ban, type: 'groupBan' });
        }
        if (this.hasAllBanPerm && this.banGroupList.length > 0) {
          opItem.push({ name: '解除全部禁言', icon: opIcon.cancelBan, type: 'cancelAllBan' });
        }

        this.$showActionSheet({
          title: this.banTitle,
          item: opItem,
          callback: (res) => {
            // console.log(res);
            if (res.ret === 0) {
              const op = opItem[res.index] || {};
              if (op.type === 'cancelAllBan') {
                this.cancelBan(true);
              } else {
                this.banType = op.type === 'allBan' ? 'all' : 'group';
                this.isShowBanUserDialog = true;
              }
            }
          },
        });
      }
    },
    handleChoice(isCancel) {
      const confirmDialog = dialog({
        confirm: true,
        confirmText: '确认',
        cancelText: '取消',
        // eslint-disable-next-line no-confusing-arrow
        content: () => (isCancel ? '是否取消加精？' : '是否将该内容加精？'),
        onConfirm: () => {
          confirmDialog.close();
          const loadingStatus = this.$loading({
            message: '操作中...',
          });


          manage.addToBest({
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
            isCancel,
          }).then(() => {
            loadingStatus.close();
            this.$toast('操作成功');
            this.update();
            this.reportData({ operid: '1501000830601', ext1: isCancel ? opMap.cancelChoice.ext1 : opMap.choice.ext1 }, '内容详情页管理操作成功');
          })
            .catch(() => {
              loadingStatus.close();
            });
        },
        onCancel: () => {
          confirmDialog.close();
        },
      });
    },
    handlePantry() {
      const confirmDialog = dialog({
        confirm: true,
        confirmText: '确认',
        cancelText: '取消',
        content: () => '是否将该内容挪动至闲谈茶水间？',
        onConfirm: () => {
          confirmDialog.close();
          const loadingStatus = this.$loading({
            message: '操作中...',
          });

          manage.changeGroup({
            groupId: this.postInfo?.groupId,
            contentId: this.postInfo?.id,
          }).then(() => {
            loadingStatus.close();
            this.$toast('操作成功');
            this.update();
            this.reportData({ operid: '1501000830601', ext1: opMap.pantry.ext1 }, '内容详情页管理操作成功');
          })
            .catch(() => {
              loadingStatus.close();
            });
        },
        onCancel: () => {
          confirmDialog.close();
        },
      });
    },
    handleStrategy() {
      this.isShowStrategyLibDialog = true;
    },
    cancelBan(isAll) {
      this.reportData({ operid: '1501000830302', ext1: isAll ? '20' : '18' }, '内容详情页管理操作入口点击');
      const loadingStatus = this.$loading({
        message: '操作中...',
      });
      manage.banUser({
        groupId: isAll ? 0 : this.postInfo?.groupId,
        contentId: this.postInfo?.id,
        banUid: this.creatorInfo?.uid,
        banTime: 0,
        banReason: 0,
        isCancel: true,
      }).then(() => {
        loadingStatus.close();
        this.$toast('操作成功');
        this.update();
        this.reportData({ operid: '1501000830601', ext1: isAll ? '20' : '18' }, '内容详情页管理操作入口点击');
      })
        .catch(() => {
          loadingStatus.close();
        });
    },
    addChoiceOp(list) { // 加精选项
      if (this.hasPerm(PERM_TAG.choice)) {
        if (isBitTrue(this.flag, 3)) {
          list.push(opMap.cancelChoice);
        } else {
          list.push(opMap.choice);
        }
      }
    },
    hasPerm(type) {
      return this.perms && this.perms[type];
    },
    addOfflineOp(list) { // 上下架选项
      const status = parseInt(this.postInfo?.status, 10);
      if (this.hasPerm(PERM_TAG.offline)) {
        if (status === 6) { // 上架了
          list.push(opMap.offline);
        }
      } else if (this.hasPerm(PERM_TAG.online)) {
        if (status === 7) { // 下架了
          list.push(opMap.online);
        }
      }
    },
    addPantryOp(list) { // 移到茶水间
      if (this.hasPerm(PERM_TAG.pantry)) {
        const groupId = parseInt(this.postInfo?.groupId, 10);
        const env = checkEnv();
        if ((env.isTest && groupId !== 88888905) || (env.isProd && groupId !== 88888916)) {
          // 圈子id判断 测试环境88888905，正式环境88888916
          list.push(opMap.pantry);
        }
      }
    },
    addBanOp(list) { // 禁言操作
      if (this.hasBanPerm) {
        if (this.isAllBan) { // 全平台禁言了
          list.push(opMap.cancelBan);
        } else if (this.isGroupBan) { // 被圈子禁言了
          if (this.hasAllBanPerm) { // 有全平台禁言权限
            list.push(opMap.ban);
          } else {
            list.push(opMap.cancelBan);
          }
        } else {
          list.push(opMap.ban);
        }
      }
    },
    addTopOp(list) { // 置顶操作
      if (this.hasPerm(PERM_TAG.top)) {
        if (isBitTrue(this.flag, 5)) {
          list.push(opMap.cancelTop);
        } else {
          list.push(opMap.top);
        }
      }
    },
    addAnnouncementOp(list) {
      if (this.hasPerm(PERM_TAG.announcement)) {
        if (isBitTrue(this.flag, 4)) {
          list.push(opMap.cancelAnnouncement);
        } else {
          list.push(opMap.announcement);
        }
      }
    },
    addStrategyOp(list) {
      if (this.hasPerm(PERM_TAG.strategy)) {
        list.push(opMap.strategy);
      }
    },
    addSinkOp(list) {
      if (this.hasPerm(PERM_TAG.sink)) {
        if (isBitTrue(this.flag, 17)) {
          list.push(opMap.cancelSink);
        } else {
          list.push(opMap.sink);
        }
      }
    },
  },

};
</script>

<style lang="scss" src="./scss/index.scss"></style>
