<template>
  <div
    :class="type"
    class="ui-notice-bar"
  >
    <div
      v-if="type==='default'"
      class="icon-alert"
    />
    <div
      v-if="type==='warning'"
      class="icon-warning"
    />
    <div
      v-if="type==='error'"
      class="icon-error"
    />
    <div class="ui-notice-bar-content ui-nowrap">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
