<template>
  <div
    class="com-post-feedback"
  >
    <div
      v-show="accuseDialogStatus"
      @touchmove="disabledTouchMove"
      @click="closeFeedbackPopup"
      class="com-post-feedback-shadow"
    />
    <div
      v-show="accuseDialogStatus"
      :class="postMain"
      class="com-post-feedback-content"
    >
      <div
        class="com-post-feedback-accuse"
      >
        <div class="com-post-feedback-accuse-title ui-border-b">
          <span class="com-post-feedback-accuse-title-blank" />
          <p class="com-comment-feedback-accuse-title-main">
            选择举报理由
          </p>
          <span class="com-post-feedback-accuse-title-blank" />
        </div>
        <div class="com-post-feedback-accuse-reason">
          <ul class="com-post-feedback-accuse-list">
            <li
              v-for="(item, index) in accuseReasonList"
              :key="index"
              @click="accuseCommentItem(item)"
              class="com-post-feedback-accuse-item ui-border-b"
            >
              {{ item.desc }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import postApi from '@/api/post';
import { checkEnv } from '@/util/browser-env';
import manage from '@/api/manage';

export default {
  name: 'ComPostAccuse',
  components: {
    //
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    postMain: {
      type: String,
      default: '',
    },
    postInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      accuseDialogStatus: false,
      accuseReasonList: [],
      accuseCommentLoading: false, // 正在accuse
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    value(newVal) {
      if (!newVal) {
        this.accuseDialogStatus = false;
      } else {
        this.showAccuseDialog();
      }
    },
  },
  methods: {
    /**
     * 禁止滚动
     * */
    disabledTouchMove(e) {
      e.preventDefault();
    },
    /**
     * 关闭反馈弹窗
     * */
    closeFeedbackPopup() {
      this.$emit('change', false);
      this.$emit('closeFeedbackPopup');
    },
    getAccuseReasonList({ errorToast = false } = {}) {
      if (this.accuseReasonList.length > 0) {
        return Promise.resolve();
      }
      return manage.getPunishReasons(2).then((result) => {
        this.accuseReasonList = result?.punishReasonList || [];
      })
        .catch(() => {
          if (errorToast) this.$toast('获取举报原因失败，请重试');
          return Promise.reject(new Error(''));
        });
    },
    showAccuseDialog() {
      this.getAccuseReasonList({ errorToast: true })
        .then(() => {
          this.accuseDialogStatus = true;
        })
        .catch(() => {
          this.$emit('change', false);
        });
    },
    closeAccuseDialog() {
      this.accuseDialogStatus = false;
    },
    accuseCommentItem(reason) {
      if (this.accuseCommentLoading) return true;
      this.accuseCommentLoading = true;
      // TODO: ios 审核需要
      if (checkEnv().isSwitchOn && (!this.userInfo || !this.userInfo.uid)) {
        this.$toast('举报成功，小编审核中');
        this.accuseCommentLoading = false;
        this.$emit('change', false);
        return false;
      }
      postApi.postAction({
        postId: this.postInfo.id,
        postCreatorId: this.postInfo.creatorId,
        postType: this.postInfo.type,
        userId: (this.userInfo && this.userInfo.uid) || 0,
        action: 'accuse', // 举报
        info: reason.desc,
      })
        .then(() => {
          this.$toast('举报成功，小编审核中');
        })
        .catch((err) => {
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          } else {
            this.$toast('举报失败，请重试');
          }
        })
        .finally(() => {
          this.$emit('change', false);
          this.accuseCommentLoading = false;
        });
      return true;
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
