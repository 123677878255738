<template>
  <div
    v-if="iconUrl"
    :style="{ backgroundImage: `url(${iconUrl})` }"
    @click.stop="handleIconClick"
    class="ui-label-badge-icon"
  />
</template>
<script>
import jsbridge from '@/jsbridge';

export default {
  name: 'BadgeIcon',
  inject: ['reportData'],
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    reportIsComment: {
      type: Boolean,
      default: false,
    },
    reportIsInvite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    iconUrl() {
      return this.getBadgeResource('icon');
    },
    badgeResource() {
      if (!this.userInfo) return [];
      return this.userInfo.userBadge.badge.badgeResource || [];
    },
  },
  mounted() {
    this.reportData({
      pageid: !this.reportIsInvite ? '1501' : '1505',
      moduleid: '00056',
      eventtype: '02',
      extendid: !this.reportIsComment ? '01' : '02',
    }, '所有内容详情页答主勋章曝光');
  },
  methods: {
    getBadgeResource(resName) {
      if (!this.badgeResource.length) return '';
      const data = this.badgeResource.find(item => item.resName === resName);
      return data ? data.url : '';
    },
    handleIconClick() {
      if (!this.userInfo || !this.userInfo.userBadge) return;
      this.reportData({
        pageid: !this.reportIsInvite ? '1501' : '1505',
        moduleid: '00056',
        eventtype: '03',
        extendid: !this.reportIsComment ? '01' : '02',
      }, '所有内容详情页答主勋章点击');
      if ((this.$compareVersion('1.3.2') >= 0 && this.$compareVersion('1.3.3') < 0) || this.$compareVersion('1.3.4') >= 0) {
        let options;
        if (this.$compareVersion('1.5.0') >= 0) {
          options = {
            uid: Number(this.userInfo.userBadge.uid),
            badgeId: Number(this.userInfo.userBadge.badge.badgeId),
          };
        } else if (this.userInfo.userBadge.badge.name === 'replier') {
          options = {
            iconUrl: this.getBadgeResource('big_icon'),
            animationUrl: this.getBadgeResource('animation'),
            levelAndNameUrl: this.getBadgeResource('name_and_level'),
            uid: Number(this.userInfo.userBadge.uid),
            avatarUrl: this.userInfo.iconUrl || this.userInfo.avatar,
            nickName: this.userInfo.nickName || this.userInfo.nickname,
            badgeNum: this.userInfo.userBadge.number,
          };
        }
        if (options) {
          jsbridge.core.call('ui/showMedalDialog', options);
        } else {
          this.$toast('勋章体系已升级，当前勋章需要升级到新版本查看，请先升级版本。');
        }
      } else {
        this.$toast('请升级最新版本查看详情');
      }
    },
  },
};
</script>
<style lang="scss">
  .ui-label-badge-icon {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>
