<template>
  <div v-bem>
    <div
      v-show="isShow"
      v-bem:shadow
      @touchmove.stop="preventDefault"
    />
    <div
      v-show="isShow"
      :class="pageType"
      class="com-ban-user-dialog__content"
    >
      <div v-bem:nav>
        <div v-bem:title>
          {{ type === 'all' ? '全平台禁言' : '本圈子禁言' }}
        </div>
        <div
          v-bem:cancel
          @click="clickHide"
        />
      </div>
      <div
        :class="pageType"
        class="com-ban-user-dialog__wrap"
      >
        <div
          v-bem:title-wrap
          class="has-desc"
        >
          <span v-bem:title>选择禁用时长</span>
          <span v-bem:desc>禁言期间，TA可以查看圈子内容，但无法发帖、评论</span>
        </div>

        <ul v-bem:list>
          <li
            v-for="(item, index) in timeList"
            :key="'time_' + index"
            :class="{current: index === currentTimeIndex}"
            @click="clickTime(item, index)"
            class="com-ban-user-dialog__item"
          >
            {{ item }}天
          </li>
        </ul>

        <div v-bem:grey-divider />

        <div v-bem:title-wrap>
          <span v-bem:title2>选择禁言理由</span>
        </div>
        <div
          v-if="reasonStatus === 'loading'"
          v-bem:status
        >
          加载中
        </div>
        <div
          v-if="reasonStatus === 'error'"
          v-bem:status
        >
          加载失败，请稍后重试
        </div>
        <ul v-bem:list>
          <li
            v-for="(item, index) in reasonList"
            :key="'reason_' + index"
            :class="{current: index === currentReasonIndex}"
            @click="clickReason(item, index)"
            class="com-ban-user-dialog__item"
          >
            {{ item.desc }}
          </li>
        </ul>
      </div>

      <div
        v-bem:button
        @click="clickConfirm"
      >
        确定
      </div>
    </div>
  </div>
</template>

<script>
import manage from '@/api/manage';

export default {
  name: 'ComBanUserDialog',
  model: {
    prop: 'isShow',
    event: 'change',
  },
  props: {
    isShow: {
      type: Boolean,
      default: () => ({}),
    },
    pageType: {
      type: String,
      default: () => ({}),
    },
    // all 全平台禁言、 group 圈子禁言
    type: {
      type: String,
      default: () => ({}),
    },
    postInfo: {
      type: Object,
      default: () => ({}),
    },
    creatorInfo: {
      type: Object,
      default: () => ({}),
    },
    reportData: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      reasonList: null,
      timeList: [1, 7, 30],
      currentTimeIndex: -1,
      currentReasonIndex: -1,
      reasonStatus: 'loading', // loading error success
    };
  },

  mounted() {
    this.initReason();
  },
  methods: {
    preventDefault(event) {
      event.preventDefault();
      return false;
    },
    initReason() {
      manage.getPunishReasons(3).then((result) => {
        this.reasonStatus = 'success';
        this.reasonList = result?.punishReasonList || [];
      })
        .catch((error) => {
          this.reasonStatus = 'error';
          this.$logger.error('getPunishReasons', error);
        });
    },
    clickHide() {
      this.$emit('change', false);
    },
    clickTime(item, index) {
      this.currentTimeIndex = index;
    },
    clickReason(item, index) {
      this.currentReasonIndex = index;
    },
    clickConfirm() {
      const cache = this.clickConfirm;
      if (this.currentTimeIndex < 0 || !this.timeList[this.currentTimeIndex]) {
        this.$toast('请选择禁言时间');
        return;
      }
      if (this.currentReasonIndex < 0 || !this.reasonList[this.currentReasonIndex]) {
        this.$toast('请选择禁言理由');
      }

      if (cache.lock) {
        return;
      }

      cache.lock = true;
      clearTimeout(cache.timer);
      cache.timer = setTimeout(() => { // 超时解锁
        cache.lock = false;
      }, 3000);
      const loadingStatus = this.$loading({
        message: '操作中...',
      });
      const banTime = this.timeList[this.currentTimeIndex] * 24 * 60 * 60;
      manage.banUser({
        groupId: this.type === 'all' ? 0 : this.postInfo?.groupId,
        contentId: this.postInfo?.id,
        banUid: this.creatorInfo?.uid,
        banTime,
        reasonId: this.reasonList[this.currentReasonIndex].id,
        isCancel: false,
      }).then(() => {
        loadingStatus.close();
        cache.lock = false;
        this.$emit('change', false);
        this.$toast('操作成功');
        this.$emit('update');
        this.reportData({
          operid: '1501000830601',
          ext1: this.type === 'all' ? '19' : '17',
          ext2: banTime,
          ext3: this.reasonList[this.currentReasonIndex].value,
        }, '内容详情页管理操作成功-禁言');
      })
        .catch((error) => {
          loadingStatus.close();
          this.$logger.error('banUser', error);
          cache.lock = false;
        });
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
