<script>
import { checkEnv } from '@/util/browser-env';
import compareVersion from '@/util/compareVersion';
import jsbridge from '@/jsbridge';
import PhotoSwipe from './photo-swipe.vue';

const checkEnvObj = checkEnv();

export default {
  name: 'UiPhotoSwipe',
  components: {
    PhotoSwipe,
  },
  // 拷贝 PhotoSwipe 组件props
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    images: { // [{url, width, height}]
      type: Array,
      default: () => [],
      validator: arr => arr.every(item => item.width && item.height && item.url),
    },
    index: { // 当前查看的图片下标
      type: Number,
      default: 0,
    },
    currentUrl: {
      type: String,
      default: '',
    },
  },
  methods: {
    showPhotoSwipe({
      index, url, images,
    }) {
      if (checkEnvObj.isApp && compareVersion('1.3.1') > -1) {
        let showIndex = index;
        // 如果传入了url，就用url找到下标
        if (url) {
          for (let i = 0; i < images.length; i++) {
            if (images[i].url === url) {
              showIndex = i;
              break;
            }
          }
        }
        jsbridge.core.call('ui/image_preview', {
          index: showIndex, images,
        });
      } else {
        this.$refs.photoSwipe.showPhotoSwipe({
          index, url, images,
        });
      }
    },
  },
  render(h) {
    return h(PhotoSwipe, {
      props: this.$options.propsData,
      ref: 'photoSwipe',
    });
  },
};
</script>
