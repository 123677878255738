/**
 * 对终端提供的数据进行适配
 * 根据 preData 中的proto字段进行匹配
 * proto对应后台结构体
 * 详情页使用的结构体是HomePagePost
 */
import { humps } from '@/util/util';

/**
  * @typedef {Object} Post 帖子信息
  * @typedef {Object} UserInfo 用户信息
  * @typedef {Object} GroupData 圈子信息
  */

/**
 * 将 PostInfo 转换为 HomePagePost
 * @param {Object} preData
 */
function transPostInfo(preData) {
  const extent = preData.extent || {};
  return {
    creator: preData.author,
    postInfo: {
      id: preData.id,
      parentId: '',
      creatorId: preData.author && preData.author.uid,
      groupId: preData.group && preData.group.id,
      status: preData.status || 6, // 把状态变成 6
      type: preData.type,
      subType: 0, // unknown
      flag: preData.flag,
      title: preData.title,
      content: '',
      pics: preData.pictureList,
      summary: preData.content,
      videoInfo: preData.video,
      components: [],
      recentReplyTime: preData.recentReplyTime,
      tags: '',
      createTime: preData.createTime,
      modifiedTime: undefined,
      channel: preData.channel || 0, // unknown
      /* 省略部分 */
      likeCnt: preData.likeNum,
      commentCnt: preData.commentNum,
      readCnt: preData.readNum,
      followCnt: preData.followNum,

      extent: {
        userActions: [],
        userActionWithPost: preData.userActionWithPost,
        ...extent,
      },
    },
    groupInfo: preData.group,
  };
}

/**
 * 规范 HomePagePost 字段
 * @param {Object} preData
 */
function transHomePagePost(preData) {
  return {
    creator: preData.creator,
    postInfo: {
      extent: {
        userActions: [],
        userActionWithPost: {},
      },
      ...preData.postInfo,
      status: preData.postInfo.status || 6, // 把状态变成 6
      content: '',
    },
    groupInfo: preData.groupInfo,
  };
}

function transDesignerRevelationData(preData) {
  if (!preData.postData) {
    throw Error('解析preData失败 transRevelationData preData fail');
  }
  return {
    creator: preData.authorData,
    postInfo: {
      id: preData.postData.postId,
      parentId: '',
      creatorId: '',
      groupId: '',
      status: 6, // 把状态变成 6
      type: preData.postData.postType,
      subType: 0, // unknown
      flag: '0',
      title: preData.postData.title,
      content: '',
      pics: null,
      summary: preData.postData.content,
      videoInfo: null,
      components: [],
      recentReplyTime: undefined,
      tags: '',
      createTime: undefined,
      modifiedTime: undefined,
      channel: 0, // unknown
      /* 省略部分 */
      likeCnt: 0,
      commentCnt: preData.postData.commentCnt,
      readCnt: preData.postData.readCnt,
      answerCnt: preData.postData.answerCnt,
      followCnt: preData.postData.followNum,

      extent: {
        userActions: [],
        userActionWithPost: null,
      },
    },
  };
}

/**
 * 对预存数据进行适配
 * @param {Object} preData 预存数据
 * @param {String} preData.proto 预存数据的格式
 */
function formatPreData(preData) {
  const data = humps.camelizeKeys(preData);
  if (!data.proto) return data;
  switch (data.proto) {
    case 'HomePagePost':
      return transHomePagePost(data);
    case 'PostInfo':
      return transPostInfo(data);
    case 'DesignerRevelationData':
      return transDesignerRevelationData(data);
    default:
      return data;
  }
}

// eslint-disable-next-line import/prefer-default-export
export { formatPreData };
