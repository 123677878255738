/**
 * 数据上报
 * 通过provie 注入到页面的子组件中
 * 页面的data中必须包含下面参数
 * reportPageId: reportEnum.pageid.POST_DETAIL,
*  reportContentType: reportEnum.contenttype.POST_DETAIL,
 */
import { mapState } from 'vuex';
import reportApi from '@/api/report';
import jsbridge, { curdData } from '@/jsbridge';
import { logger } from '@/util/logger';

export default {
  data() {
    return {
      isScrollEventReported: false,
      // {watch: Observable[], el: DOMELEMENT, scrollTop: Number, height: Number, reportParams: params}
      exposeElementStore: [],
      staytimeStart: 0,
      recommend_extends: null,
      pageShowTime: 0,
      pageReportParams: {}, // 页面公共上报参数
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      userInfo: state => state.user.userInfo,
      isInBackground: state => state.app.isInBackground,
    }),
  },
  watch: {
    pageLoading(newVal, oldVal) {
      if (!newVal && oldVal && this.isApp && !this.isInBackground) {
        this.pageShowTime = (new Date()).getTime();
        this.reportWebbundle();
      }
    },
  },
  provide() {
    return {
      reportData: this.reportData,
    };
  },
  mounted() {
    // console.log('postReportData mounted');
    this.bindScroll();
    this.registerAppEvent();
    this.reportStayTimeH5();
    if (!this.pageLoading && this.isApp && !this.isInBackground) {
      this.pageShowTime = (new Date()).getTime();
      this.reportWebbundle();
    }
    if (this.isApp) {
      curdData({
        key: 'post_detail_temp',
        action: 1,
        user_related: 0,
        level: 2,
      }).then((res) => {
        logger.info('curdData, post_detail_temp res', res);
        if (res.data) {
          try {
            const data = JSON.parse(res.data);
            if (Number(data.post_id) === Number(this.postId)) {
              logger.info('post_detail_temp match post_id');
              this.rcmdInfo = JSON.parse(data.rcmd_info);
              curdData({
                key: 'post_detail_temp',
                action: 2,
                user_related: 0,
                level: 2,
              });
            }
          } catch (error) {
            logger.error('解析 curdData失败', error);
          }
        }
      })
        .catch((err) => {
          logger.error('curdData error', err);
        });
    }
  },
  beforeDestroy() {
    this.unbindScroll();
    if (this.isApp) {
      this.reportStayTime();
    } else {
      const now = (new Date()).getTime();
      const staytime = Math.round((now - this.staytimeStart) / 1000);
      if (staytime > 0 && staytime < 3600) {
        this.staytimeStart = now;
        this.reportData({
          pageid: '2000', // 通用上报
          moduleid: 1,
          eventtype: 6,
          extendid: 1,
          staytime,
        }, '停留时间上报');
      }
    }
  },
  methods: {
    reportData(params, desc) {
      const obj = {
        indexid: 1, // 默认是1，列表里面应该传第几项
      };
      if (this.reportPageId) {
        obj.pageid = this.reportPageId;
      }
      if (this.reportContentType) {
        obj.contenttype = this.reportContentType;
      }
      if (this.$route.query.pageFrom) {
        obj.prev_id = this.$route.query.pageFrom;
      }
      if (this.postInfo) {
        /* ex7: 内容来源
          后端定义
          CHANNEL_UNKNOWN=0;
          CHANNEL_PENGUIN=1; //企鹅号
          CHANNEL_APP=2; // app端
          CHANNEL_AUTHOR=3; //作者端
        */
        if (this.postInfo.components && this.postInfo.components.lotteryId) {
          obj.ext1 = '1';
        } else {
          obj.ext1 = '2';
        }
        const source = String(this.postInfo.channel);
        Object.assign(obj, {
          contentid: String(this.postInfo.id),
          contentname: String(this.postInfo.title),
          ext6: String(this.postInfo.groupId),
          ext7: source,
          ext9: this.postInfo.topicId || '',
          ...this.pageReportParams,
          ...params,
        });
      }
      if (this.creatorInfo) {
        Object.assign(obj, {
          authorname: String(this.creatorInfo.nickName),
          authorid: String(this.creatorInfo.uid),
          authortype: String(this.creatorInfo.accountType),
        });
      }
      if (this.rcmdInfo) {
        obj.recommend_extends = this.rcmdInfo.extends || {};
        obj.recommend_docid = this.rcmdInfo.recommend_doc_id || '';
      }
      return reportApi(obj, desc);
    },
    // 滚动
    getScrollEventTarget() {
      let currentNode = this.$el;
      // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
      while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
        const { overflowY } = getComputedStyle(currentNode);
        if (overflowY === 'scroll' || overflowY === 'auto') {
          return currentNode;
        }
        currentNode = currentNode.parentNode;
      }
      return window;
    },
    bindScroll() {
      const scrollTarget = this.getScrollEventTarget();
      scrollTarget.addEventListener('scroll', this.handlerScroll);
    },
    unbindScroll() {
      const scrollTarget = this.getScrollEventTarget();
      scrollTarget.removeEventListener('scroll', this.handlerScroll);
    },
    handlerScroll() {
      if (!this.isScrollEventReported) {
        this.isScrollEventReported = true;
        this.reportData({
          moduleid: 1,
          eventtype: 4,
          extendid: 1,
        }, '页面上下滑动');
      }
    },
    registerAppEvent() {
      if (!this.isApp) return;
      this.staytimeStart = (new Date()).getTime();
      jsbridge.ui.setOnVisibilityChange({
        callback: (res) => {
          // console.log('res.data.visibilityState ', res.data.visibilityState);
          if (window.jsbridge && window.jsbridge.log) {
            window.jsbridge.log(1, 'H5LOG!', 'setOnVisibilityChange');
          }
          if (res.result === 0) {
            if (!res.data.visibilityState) {
              this.reportStayTime();
            } else {
              this.staytimeStart = (new Date()).getTime();
            }
          }
        },
      });
    },
    reportStayTime() {
      if (!this.isApp || !this.staytimeStart) return;
      const now = (new Date()).getTime();
      const staytime = Math.round((now - this.staytimeStart) / 1000);
      this.staytimeStart = now;
      if (staytime > 0 && staytime < 3600) { // 去除脏数据
        // 数据上报: 所有内容详情页停留时长, operid: 1501000010601
        this.reportData({
          pageid: '1501', // 通用上报
          moduleid: 1,
          eventtype: 6,
          extendid: 1,
          staytime,
          ext5: '1', // 标识新修改的上报
          ext10: this.postInfo?.extent?.voteInfos?.[0]?.id || '',
        }, {
          description: '停留时间上报',
          saveToLocal: true, // 存在本地
        });
      }
    },
    reportStayTimeH5() {
      // 上报分享H5停留时长
      if (this.isApp) return;
      this.staytimeStart = (new Date()).getTime();
      document.addEventListener('visibilitychange', () => {
        // console.log('visibilitychange', event);
        if (document.hidden) {
          const now = (new Date()).getTime();
          const staytime = Math.round((now - this.staytimeStart) / 1000);
          if (staytime > 0 && staytime < 3600) {
            this.staytimeStart = now;
            this.reportData({
              pageid: '2000', // 通用上报
              moduleid: 1,
              eventtype: 6,
              extendid: 1,
              staytime,
              ext5: '1', // 标识新修改的上报
            }, '停留时间上报');
          }
        } else {
          this.staytimeStart = (new Date()).getTime();
          // console.log('页面展示', this.staytimeStart);
        }
      }, false);
    },
    reportWebbundle() {
      // 放在异步中上报，防止终端 nativeStartTime 变量还未注入
      setTimeout(() => {
        const { nativeStartTime } = window; // 终端传入的打开页面时间
        logger.info(`call reportWebbundle: nativeStartTime = ${nativeStartTime} pageShowTime=${this.pageShowTime}`);
        // console.log(`call reportWebbundle: nativeStartTime = ${nativeStartTime} pageShowTime=${this.pageShowTime}`);
        const { dataSource } = this;
        const { pageOpenType } = this.$route.query;
        let openType = 2; // 打开方式: 0=webbundle摘要打开;1=无摘要;2=关闭webbundle;
        if (Number(pageOpenType) === 1 && dataSource === 'preData') {
          openType = 0;
        } else if (Number(pageOpenType) === 1) {
          openType = 1;
        }
        if (nativeStartTime && this.pageShowTime) {
          const duration = this.pageShowTime - Number(nativeStartTime);
          logger.info(`详情页打开计时 openType=${openType} duration=${duration}`);
          if (duration < 20000) { // 过滤脏数据
            this.reportData({
              pageid: 1613,
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              staytime: duration,
              ext1: openType,
              ext2: dataSource === 'local' ? 1 : 0, // 是否使用了本地数据打开
            }, 'webbundle打开上报');
          }
          window.nativeStartTime = 0;
        } else {
          logger.error('终端未传入 nativeStartTime');
        }
      }, 1000);
    },
  },
};
