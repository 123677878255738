<template>
  <div
    @click="handleClickVoteOption"
    class="vote-option-in-app"
  >
    <div
      :style="resultBarStyle"
      class="result-bar"
    />
    <img
      v-if="iconUrl"
      :src="iconUrl"
      class="vote-icon"
    >
    <div :class="descriptionClassName">
      {{ description }}
    </div>
    <div
      v-if="isChoosed"
      class="choose-icon"
    />
    <div
      v-if="showVoteDetail"
      class="countInfo"
    >
      {{ countInfo }}
    </div>
  </div>
</template>

<script>
export default {
  /** App内的投票选项组件 */
  name: 'VoteOptionInApp',
  props: {
    // 投票选项基本信息
    // message VoteChoice {
    //   string name = 1 [(desc) = "名称"];
    //   string pic_url = 2 [(desc) = "图片"];
    // }
    // 投票选项返回信息
    // message VoteChoiceInfo {
    //   VoteChoice choice = 1 [(desc) = "选项信息"];
    //   uint64 num = 2 [(desc) = "票数, 投票后才展示"];
    //   bool is_chosen = 3 [(desc) = "是否选择"];
    // }
    index: {
      type: Number,
      default: 0,
    },
    choiceInfo: {
      type: Object,
      default: () => ({}),
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    hasVoted: {
      type: Boolean,
      default: false,
    },
    isActivityOvertime: {
      type: Boolean,
      default: false,
    },
    voteRenderCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showVoteDetail() {
      return this.hasVoted || this.isActivityOvertime;
    },
    iconUrl() {
      const url = this.choiceInfo.choice.picUrl;
      return url;
    },
    description() {
      const description = this.choiceInfo.choice.name;
      return description;
    },
    count() {
      const count = this.choiceInfo.num;
      return count;
    },
    isChoosed() {
      const isChoosed = this.choiceInfo.isChosen;
      return isChoosed;
    },
    showAnimation() {
      // 由于投票组件有可能被多次渲染(首次显示会被渲染2次)，在第2次渲染之后，才显示相关的动画
      return this.voteRenderCount > 2;
    },
    resultBarStyle() {
      const width = 303;
      const offsetWidth = this.iconUrl ? 42 : 0;
      const scale = Math.min(this.count / (this.totalCount || 1), 1);
      // 有图标时，投票bar条显示比例，需要考虑图片的宽度
      let translateX = (width - offsetWidth) * scale;
      if (translateX) {
        translateX += offsetWidth;
      }
      const commonStyle = {
        width: `${translateX}px`,
        transform: `translateX(-${translateX}px)`,
      };

      if (this.showVoteDetail) {
        const background = this.isChoosed ? '#FFE363' : '#EDEDED';
        commonStyle.background = background;
        if (this.showAnimation) {
          commonStyle.animation = 'showAnimation 1s ease-in-out forwards';
        } else {
          commonStyle.transform = 'translateX(0)';
        }
      }
      return commonStyle;
    },
    descriptionClassName() {
      return this.iconUrl ? 'description has-icon' : 'description';
    },
    countInfo() {
      return `${this.count || 0}票`;
    },
  },
  methods: {
    handleClickVoteOption() {
      if (this.isActivityOvertime) {
        return;
      }

      this.$emit('clickVoteOption', this.index);
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
