/**
 * 投票API
 */
import { gameplusApi } from '@/util/http';

export default {
  /**
   * 投票
   * @param { Object } params
   * @param { String } params.id 投票id
   * @param { Array<Number> } params.voteChoices 选项
   * @return { Object } 返回获取结果
   * */
  postVote({ id, voteChoices } = {}) {
    return gameplusApi.request({
      url: '/community.VoteSrv/Vote',
      data: {
        id,
        vote_choices: voteChoices,
      },
      options: {
        errorHandler: false,
      },
    }).then((res) => {
      if (res?.ret === 0) {
        return res.voteInfo;
      }
      return Promise.reject(res);
    });
  },
  /**
   * 取消投票
   * @param { Object } params
   * @param { String } params.id 投票id
   * @return { Object } 返回获取结果
   * */
  postCancelVote({ id } = {}) {
    return gameplusApi.request({
      url: '/community.VoteSrv/CancelVote',
      data: {
        id,
      },
      options: {
        errorHandler: false,
      },
    }).then((res) => {
      if (res?.ret === 0) {
        return res.voteInfo;
      }
      return Promise.reject(res);
    });
  },
};
