<template>
  <div class="ui-icon-label">
    <Icon
      v-if="icon"
      :name="icon"
      class="ui-icon-label__icon"
      size="s"
    />
    <img
      v-else-if="picUrl"
      :src="picUrl"
      class="ui-icon-label__pic"
    >
    <span class="ui-icon-label__text">
      {{ text }}
    </span>
  </div>
</template>

<script>
import Icon from '../icon';

export default {
  name: 'IconLabel',
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    picUrl: {
      type: String,
      default: null,
    },
    picShape: {
      type: String,
      default: 'circle',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  mounted() {
    this.$emit('updated'); // 会多次触发，注意使用once修饰符
  },
  updated() {
    this.$emit('updated'); // 会多次触发，注意使用once修饰符
  },
};
</script>
<style lang="scss">
.ui-icon-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px 0 3px;
  height: 20px;
  color: #737373;
  font-size: 12px;
  background-color: #f7f7f7;
  border-radius: 10px;
  &__icon {
    // margin-right: 3px;
  }

  &__pic {
    width: 14px;
    height: 14px;
    margin-right: 3px;
    border: none;
    border-radius: 50%;
    overflow: hidden;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
