/**
 * CDN 图片裁剪&图片走终端&webp转换
 */
import { env } from '@/util/browser-env';
import tgcimg from '@/plugin/prototype/tgcimg';
import compareVersion from '@/util/compareVersion';

// 图片裁剪域名白名单
const cdnDomains = [
  /^https?:\/\/static.gameplus.qq.com/,
  /^https?:\/\/test-static.gameplus.qq.com/,
  /^https?:\/\/gameplus-test-1258344700/,
  /^https?:\/\/gameplus-1258344700/,
];

/**
 *
 * @param {String} url 传入URL
 * @param {Number} width 图片的真实宽度
 * @returns {String} 加入裁剪参数后的url
 */
export default function clipImageUrl(url, width) {
  let resultUrl = url;
  if (cdnDomains.some((item => item.test(url)))) {
    let imageWidth = width;
    if (imageWidth && imageWidth > 80) {
      // 从 80 - 800 进行缩放
      if (imageWidth > 800) {
        imageWidth = 800;
      } else {
        imageWidth = Math.ceil(imageWidth / 80) * 80;
      }
      resultUrl = `${resultUrl}?imageView2/2/w/${imageWidth}`;
    }

    // webp转换。ios 14以上才支持，暂不启用
    if (env.isApp && env.android) {
      if (resultUrl.indexOf('?') > -1) {
        resultUrl += '|imageMogr2/format/webp|';
      } else {
        resultUrl += '?imageMogr2/format/webp|';
      }
    }
    // tgc协议转换
    if (/^https?/.test(resultUrl) && compareVersion('1.2') > -1) {
      resultUrl = tgcimg(resultUrl);
    }
  }
  return resultUrl;
}
