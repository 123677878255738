/** 投票类型 */
const VOTE_TYPE = {
  /** 未知 */
  unknown: 0,
  /** 单选 */
  single: 1,
  /** 多选 */
  multiple: 2,
};

/** 投票类型标题 */
const VOTE_TYPE_TITLE = {
  [VOTE_TYPE.unknown]: '',
  [VOTE_TYPE.single]: '单选',
  [VOTE_TYPE.multiple]: '多选',
};

/** 投票人状态 */
const VOTER_STATUS = {
  /** 未知 */
  unknown: 0,
  /** 已投 */
  voted: 1,
  /** 未投 */
  notVoted: 2,
};

/** 投票状态 */
const VOTE_STATUS = {
  /** 未知 */
  unknown: 0,
  /** 未知 */
  unStart: 1,
  /** 投票中 */
  pending: 2,
  /** 投票结束 */
  end: 3,
};

/** 投票按钮类型 */
const VOTE_BUTTON_TYPE = {
  /** 没有按钮 */
  none: 0,
  /** ”确定投票“按钮 */
  vote: 1,
  /** “我来说几句”按钮 */
  comment: 2,
};

/** 投票按钮文本 */
const VOTE_BUTTON_TEXT = {
  [VOTE_BUTTON_TYPE.none]: '',
  [VOTE_BUTTON_TYPE.vote]: '确定投票',
  [VOTE_BUTTON_TYPE.comment]: '我来说几句',
};

/** 投票组件的滑动类型 */
const VOTE_SCROLL_TYPE = {
  /** 不做任何操作 */
  none: 0,
  /** 滑动至可视区域内 */
  inView: 1,
};

/** 投票组件的评论类型 */
const VOTE_COMMENT_TYPE = {
  /** 不做任何操作 */
  none: 0,
  /** 调起评论 */
  provokeComment: 1,
};

export {
  VOTE_TYPE,
  VOTE_TYPE_TITLE,
  VOTER_STATUS,
  VOTE_STATUS,
  VOTE_BUTTON_TYPE,
  VOTE_BUTTON_TEXT,
  VOTE_SCROLL_TYPE,
  VOTE_COMMENT_TYPE,
};
