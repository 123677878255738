/* eslint-disable max-len */
/*
* 获取用户圈子的管理员类型
* */
export function getGroupOwnerType(creatorInfo, groupInfo) {
  let groupOwnerType = '';
  if (groupInfo && groupInfo.owners && groupInfo.owners.bigOwners && groupInfo.owners.bigOwners.indexOf(String(creatorInfo?.uid)) !== -1) {
    groupOwnerType = 'big';
  } else if (groupInfo && groupInfo.owners && groupInfo.owners.smallOwners && groupInfo.owners.smallOwners.indexOf(String(creatorInfo?.uid)) !== -1) {
    groupOwnerType = 'small';
  }
  return groupOwnerType;
}
