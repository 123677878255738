/**
 * 工具库函数
 */

/**
 *
 * @param {String} html 原始html字段
 * @param {Array} picList 后台返回的picList，里面有宽高信息
 * @returns {String} resultHtml 转换后的html
 */
export function transferImgTag(html, picList) {
  const getImgTag = (imgTagStr) => {
    const tempObj = {};
    imgTagStr.replace(/(src|width|height)=["']?(.*?)["']?[\s+>]/g, (match1, $11, $21) => {
      tempObj[$11] = $21;
      return match1;
    });
    const imgSrc = tempObj.src || '';
    let w = tempObj.width || 0;
    let h = tempObj.height || 0;
    if (!imgSrc) return '';
    if ((w <= 0 || h <= 0) && Array.isArray(picList)) {
      const o = picList.filter(item => item.url === imgSrc)[0];
      if (o && Number(o.width) > 0 && Number(o.height) > 0) {
        w = o.width;
        h = o.height;
      }
    }
    if (w > 0 && h > 0) {
      return `<div class="com-post-text-pic-container" data-width="${w}" data-height="${h}" data-url="${imgSrc}"><img class="image-preview" src="${imgSrc}" /></div>`;
    }
    return `<div class="com-post-text-content-img-container"><img class="image-preview" src="${imgSrc}" /></div>`;
  };
  const result = html
    .replace(/(\r\n|\n|\r)/g, '<br />')
    .replace(/<(p|div)[^<>]*?>(.*?)<\/(p|div)>/g, (matchDiv, $1, $2) => {
      // 这种情况只适合 img标签被div单独包裹的情况
      if (/^\s*<img(.*)>\s*$/g.test($2)) {
        return getImgTag($2);
      }

      // div内部的img进行替换
      // eslint-disable-next-line no-unused-vars
      const p = $2.replace(/<img.*?src="(.*?)".*?\/?>/g, (matchImg, imgSrc) => getImgTag(matchImg));
      return `<p class="com-post-text-content-p">${p}</p>`;
    });
  return result;
}
