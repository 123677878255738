/**
 * 本地缓存详情页
 */

import { isServerRender } from '@/util/env';
import { logger } from '@/util/logger';

class PageLocalStore {
  constructor({
    moduleName, // 模块名字，相同模块名字共享存储空间
    maxSize = 50, // 最大存储容量
    expire = 30 * 24 * 60 * 60 * 1000, // 默认过期时间 30 天
    // maxSize = 10, // 测试
    // expire = 10 * 60 * 1000, // 测试
  }) {
    this.storeKey = `page_store__${moduleName}`;
    this.maxSize = maxSize;
    this.expire = expire;
  }

  // 查找数据
  find(id) {
    if (isServerRender) {
      return null;
    }
    try {
      let list = localStorage.getItem(this.storeKey);
      if (list) {
        list = JSON.parse(list);
      } else {
        return null;
      }
      // 过滤过期时间
      const now = (new Date()).getTime();
      list = list.filter(item => item.timestamp + this.expire > now);
      let target = null;
      let i = 0;
      for (; i < list.length; i++) {
        // 按顺序查找，排前面的优先
        if (list[i].id === id && list[i].data) {
          target = list[i].data;
          break;
        }
      }
      if (target && i > 0) {
        // 把命中元素提到前面
        list = [
          list[i],
          ...list.slice(0, i),
          ...list.slice(i + 1),
        ];
      }
      list = list.slice(0, this.maxSize); // 多余的去掉
      localStorage.setItem(this.storeKey, JSON.stringify(list));
      return target;
    } catch (error) {
      logger.error('LocalStore find error', error);
      return null;
    }
  }

  // add增加数据
  add(id, data) {
    if (isServerRender) {
      return;
    }
    try {
      let list = localStorage.getItem(this.storeKey);
      if (list) {
        list = JSON.parse(list);
      } else {
        list = [];
      }
      list = list.filter(item => item.id !== id); // 去重
      list.unshift({
        id,
        data,
        timestamp: (new Date()).getTime(),
      });
      list = list.slice(0, this.maxSize); // 多余的去掉
      localStorage.setItem(this.storeKey, JSON.stringify(list));
    } catch (error) {
      logger.error('LocalStore add error', error);
    }
  }

  // 删除数据
  delete(id) {
    if (isServerRender) {
      return;
    }
    try {
      let list = localStorage.getItem(this.storeKey);
      if (list) {
        list = JSON.parse(list);
      } else {
        list = [];
      }
      list = list.filter(item => item.id !== id);
      list = list.slice(0, this.maxSize); // 多余的去掉
      localStorage.setItem(this.storeKey, JSON.stringify(list));
    } catch (error) {
      logger.error('LocalStore add error', error);
    }
  }

  // 清空缓存
  clear() {
    if (isServerRender) {
      return;
    }
    try {
      // 设置为空列表
      localStorage.setItem(this.storeKey, JSON.stringify([]));
    } catch (error) {
      logger.error('LocalStore clear error', error);
    }
  }
}

export default PageLocalStore;
